import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTransition, animated } from "react-spring";
import { useLiff } from "react-liff";

import { branch } from "../../services/Api/Module/Customer";

import CardBranch from "../../components/card/branch/CardBranch";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const MyBranch = ({ articleItem }) => {
  let height = 0;

  const transitions = useTransition(
    articleItem.map((data) => ({
      ...data,
      y:
        (height += data.props.curHeight ? data.props.curHeight + 1 : 216) -
        (data.props.curHeight ? data.props.curHeight + 1 : 216),
    })),
    {
      key: (item) => item.key,
      from: { height: 0, opacity: 0, transform: "translateY(30px)" },
      enter: ({ y, height }) => ({
        y,
        height,
        opacity: 1,
        transform: "translateY(0)",
      }),
      leave: { height: 0, opacity: 0, transform: "translateY(30px)" },
      update: ({ data, y, height }) => ({ data, y, height }),
    }
  );

  return (
    <div
      className="item-group"
      style={{
        position: "relative",
        // height: height,
        height: height + articleItem.length * 16,
      }}
    >
      {transitions((style, item, t, index) => (
        <animated.div
          className="item-branch"
          style={{
            ...style,
            zIndex: articleItem.length - index,
            willChange: "transform, height, opacity",
          }}
        >
          {item}
        </animated.div>
      ))}
    </div>
  );
};

const BranchList = () => {
  const stateProfile = useSelector((state) => state.profile);

  let { liff } = useLiff();

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    if (page === "") {
      if (liff.isInClient()) {
        liff.closeWindow();
      } else {
        navigate("/");
      }
    } else {
      navigate("/" + page);
    }
  };
  /* End Handle goto page */

  /* First event render */
  const [isLoading, setIsLoading] = useState(true);

  const [isHasMyLocation, setIsHasMyLocation] = useState(null);
  const [myLocation, setMyLocation] = useState({
    latitude: null,
    longitude: null,
  });

  const [initItem, setInitItem] = useState([]);
  const [initHeight, setInitHeight] = useState([]);
  const [curHeight, setCurHeight] = useState([]);

  const [branchItem, setBranchItem] = useState([]);
  const [articleItem, setArticleItem] = useState([]);

  const getBranch = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await branch({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getBranch resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getBranch >> ", e);
    }

    return _return;
  };

  const getLocationCallback = (position) => {
    // console.log(position);

    setMyLocation({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    });

    setIsHasMyLocation(true);
  };

  const getLocationErrorCallback = (error) => {
    // console.log(error);

    setIsHasMyLocation(false);
  };

  useEffect(() => {
    // console.log("useEffect !", stateProfile);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        getLocationCallback,
        getLocationErrorCallback
      );
    } else {
      console.log("Geolocation is not available in your browser.");

      setIsHasMyLocation(false);
    }
  }, []);
  /* End First event render */

  useEffect(() => {
    // console.log(stateProfile, isHasMyLocation, myLocation);

    if (isHasMyLocation !== null) {
      getBranch({}).then((_rsDataBranch) => {
        // console.log("_rsDataBranch >>", _rsDataBranch);

        if (
          _rsDataBranch.status === true &&
          _rsDataBranch.result.length !== 0
        ) {
          const _length = _rsDataBranch.result.length;
          let _branch = _rsDataBranch.result;
          // console.log(isHasMyLocation, _branch);

          if (stateProfile.id === 0 && !isHasMyLocation) {
            console.log("account not verify & not allow location");

            // const _branchCode = [1, 4, 10, 60, 132];
            const _branchCode = [135, 127, 60, 16, 123, 155];
            const _province = [
              "กรุงเทพมหานคร",
              "นครปฐม",
              "นนทบุรี",
              "ปทุมธานี",
              "สมุทรปราการ",
              "สมุทรสาคร",
            ];
            let _newBranch = [],
              _tmpBranch = [];

            for (let i = 0; i < _branchCode.length; i++) {
              _tmpBranch = _branch.filter((item) => {
                if (item.code !== null) {
                  return item.code === _branchCode[i];
                }
              });
              _newBranch = _newBranch.concat(_tmpBranch);
            }

            for (let i = 0; i < _province.length; i++) {
              _tmpBranch = _branch.filter((item) => {
                if (item.province !== null) {
                  return (
                    item.province.toString().trim() === _province[i] &&
                    !_branchCode.includes(item.code)
                  );
                }
              });
              _newBranch = _newBranch.concat(_tmpBranch);
            }

            _tmpBranch = _branch.filter((item) => {
              if (item.province !== null) {
                return (
                  !_province.includes(item.province.toString().trim()) &&
                  !_branchCode.includes(item.code)
                );
              }
            });
            _newBranch = _newBranch.concat(_tmpBranch);

            for (let i = 0; i < _newBranch.length; i++) {
              setInitItem((prevInitItem) => [...prevInitItem, _newBranch[i]]);
            }
          } else if (stateProfile.id !== 0 && !isHasMyLocation) {
            console.log("account verified & not allow location");

            let _myBranch = {},
              _newBranch = _branch,
              _tmpBranch = _branch.filter((item) => {
                return parseInt(item.id) === stateProfile.branch;
              });
            if (
              typeof _tmpBranch.length !== "undefined" &&
              _tmpBranch.length !== 0
            ) {
              _myBranch = _tmpBranch[0];
            }

            for (let i = 0; i < _length; i++) {
              if (
                _newBranch[i].latitude !== "0" &&
                _newBranch[i].longitude !== "0"
              ) {
                const _locationA = new window.google.maps.LatLng(
                  _myBranch.latitude,
                  _myBranch.longitude
                );
                const _locationB = new window.google.maps.LatLng(
                  _newBranch[i].latitude,
                  _newBranch[i].longitude
                );

                const _dist =
                  window.google.maps.geometry.spherical.computeDistanceBetween(
                    _locationA,
                    _locationB
                  );
                _newBranch[i].distance = parseFloat((_dist / 1000).toFixed(2)); //_dist;
              }
            }

            _newBranch.sort((a, b) => a.distance - b.distance);

            for (let i = 0; i < _length; i++) {
              setInitItem((prevInitItem) => [...prevInitItem, _newBranch[i]]);
            }
          } else if (isHasMyLocation) {
            console.log("(account verified or not verify) & allow location");

            if (initItem.length !== 0) {
              setInitItem([]);
              setInitHeight([]);
              setCurHeight([]);
              setBranchItem([]);
              setArticleItem([]);
            }

            let _newBranch = _branch;

            for (let i = 0; i < _length; i++) {
              const _locationA = new window.google.maps.LatLng(
                myLocation.latitude,
                myLocation.longitude
              );
              const _locationB = new window.google.maps.LatLng(
                _newBranch[i].latitude,
                _newBranch[i].longitude
              );
              const _dist =
                window.google.maps.geometry.spherical.computeDistanceBetween(
                  _locationA,
                  _locationB
                );
              _newBranch[i].distance = parseFloat((_dist / 1000).toFixed(2)); //_dist;
            }

            _newBranch.sort((a, b) => a.distance - b.distance);

            for (let i = 0; i < _length; i++) {
              setInitItem((prevInitItem) => [...prevInitItem, _newBranch[i]]);
            }
          }

          for (let i = 0; i < _length; i++) {
            setInitHeight((prevCurHeight) => [...prevCurHeight, 0]);
          }
        }

        setIsLoading(false);
      });
    }
  }, [isHasMyLocation]);

  /* Set new height on resize */
  let _tmpHeight = [];

  const setNewHeight = (index, height) => {
    // console.log(index, height, _tmpHeight);
    if (height !== 0) {
      _tmpHeight[index] = height;
      setCurHeight(_tmpHeight);
    }
  };
  /* End Set new height on resize */

  useEffect(() => {
    // console.log(initItem.length, initItem);

    if (initItem.length > 0) {
      for (let i = 0; i < initItem.length; i++) {
        setBranchItem((prevBranchItem) => [
          ...prevBranchItem,
          <CardBranch
            key={i + 1}
            index={i}
            setNewHeight={setNewHeight}
            curHeight={initHeight[i]}
            dataBranch={initItem[i]}
            isHasMyLocation={isHasMyLocation}
          />,
        ]);

        _tmpHeight[i] = initHeight[i];
      }
    }
  }, [initItem]);

  useEffect(() => {
    if (branchItem) {
      // console.log(branchItem.length, branchItem);

      setArticleItem(branchItem);
    }
  }, [branchItem]);

  useEffect(() => {
    // console.log(curHeight.length, curHeight);

    if (curHeight.length > 0) {
      curHeight.map((val, idx) => {
        setBranchItem((prevBranchItem) =>
          prevBranchItem.with(
            idx,
            <CardBranch
              key={branchItem[idx].key}
              index={idx}
              setNewHeight={setNewHeight}
              curHeight={val}
              dataBranch={branchItem[idx].props.dataBranch}
              isHasMyLocation={isHasMyLocation}
            />
          )
        );
      });
    }
  }, [curHeight]);

  useEffect(() => {
    // console.log(articleItem.length, articleItem);
  }, [isLoading, articleItem]);

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageBranchDetail">
        <header>
          <div className="tBranchHeader">
            <h1>ค้นหาร้านยาของเรา</h1>
          </div>
        </header>
        <div class="bSelect">
          <div class="selectBranch">
            <a class="active" href="#" onClick={handleGotoPage} data-page="branch">
              สาขาใกล้ฉัน
            </a>
            <a class="" href="#" onClick={handleGotoPage} data-page="branch/map">
              สาขาทั้งหมด
            </a>
          </div>
        </div>
        <main>
          <div className="wrapContent">
            <section id="pBranchDetail">
              <div className="imgBranch"></div>
              <div className="bBranchDetails">
                <p className="tHead margin-0">สาขาใกล้ฉัน</p>
                {isLoading ? (
                  <p className="loading">
                    <FontAwesomeIcon icon={faSpinner} spin /> Loading...
                  </p>
                ) : (
                  <>
                    {initItem.length === 0 && articleItem.length === 0 ? (
                      <p className="loading">Not_Found_Branch</p>
                    ) : (
                      <MyBranch articleItem={articleItem} />
                    )}
                  </>
                )}
              </div>
            </section>

            <section id="pActionBtn">
              <div className="bBtn">
                <button
                  className="btn outline"
                  onClick={handleGotoPage}
                  data-page=""
                >
                  ย้อนกลับ
                </button>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default BranchList;
