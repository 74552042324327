import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { healthCardById } from "../../../../../services/Api/Module/HealthService";
import moment from "moment";
import { Helmet } from "react-helmet";

const DetailPressure = () => {
  const navigate = useNavigate();
  const mockHead = "ข้อมูลค่าความดัน";
  const location = useLocation();
  const id = location.state?.id;
  const stateProfile = useSelector((state) => state.profile);
  const [data, setData] = useState([]);
  const fetchGetHealthCardById = async (id, payload) => {
    try {
      const response = await healthCardById({ cardId: id, params: payload });
      const resData = response?.data;
      setData(resData.body);
    } catch (err) {
      console.log("err ", err);
    }
  };

  useEffect(() => {
    if (stateProfile.id === 0) {
      navigate("/");
    }
    let payload = {
      customerId: stateProfile.id,
    };
    fetchGetHealthCardById(id, payload);
  }, []);

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageHealthServiceDetail">
        <main>
          <div className="wrapContent">
            <section id="pHealthServiceDetail">
              <div className="bContent">
                <div className="tHead">
                  <h2>{mockHead}</h2>
                </div>
                <div className="bDetail">
                  <div className="bSave">
                    <div className="bCal">
                      <div className="tGraph">
                        <div className="colorGauge">
                          <div className="colorBlock">
                            <div className="color2"></div>
                            <p className="tColor2">ต่ำ</p>
                          </div>
                          <div className="colorBlock">
                            <div className="color3"></div>
                            <p className="tColor3">ปกติ</p>
                          </div>
                          <div className="colorBlock">
                            <div className="color4"></div>
                            <p className="tColor4">สูง</p>
                          </div>
                          <div className="colorBlock">
                            <div className="color6"></div>
                            <p className="tColor6">สูงมาก</p>
                          </div>
                        </div>
                        <div
                          className="tPoint"
                          style={{
                            left: `${data?.mechanicPressure?.percentTag}%`,
                          }}
                        ></div>
                      </div>
                      <div className="bDataCurrent">
                        <p className="tBold">ค่าปัจจุบัน :</p>
                        <p className="tResult">
                          {data?.healthInfo?.sbp}/{data?.healthInfo?.dbp}
                        </p>
                        <p className="tBold">สถานะ :</p>
                        <p className="tResult">
                          {data?.mechanicPressure?.label}
                        </p>
                        <p className="tBold">บันทึกล่าสุด :</p>
                        <p className="tResult">
                          {" "}
                          {moment(data?.healthInfo?.createdOn)
                            .locale("th")
                            .format("DD/MM/YYYY HH.mm น.")}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="bHealthData">
                    <p>ตารางวัดผล</p>
                    <div className="bImg">
                      <img src="../../../assets/img/ci/ico-healthservice/detail-pressure.svg" />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="pActionBtn">
              <div className="bBtn">
                <button
                  className="btn outline"
                  onClick={() =>
                    navigate("/healthservice-history", { state: { id: id } })
                  }
                >
                  <p>ย้อนกลับ</p>
                </button>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default DetailPressure;
