import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useTranslations from "../../i18n/useTranslations";

import img_picHeadBanner from "../../assets/img/ci/pic-head-banner.png";
import img_icoBanner from "../../assets/img/ci/icon-banner/ico-banner.png";

function PageTitleGuest(props) {
  const stateProfile = useSelector((state) => state.profile); // ไว้ดึง state

  const { t } = useTranslations();

  /* First event render */
  // useEffect(() => {
  //   console.log(stateProfile.lineUid);
  // }, []);
  /* End First event render */

  return (
    <>
      <section id="pTiltlePage">
        <img className="bannerPage" src={img_picHeadBanner} alt="" />
      </section>
      <section id="pTitleContentMain">
        <div className="bTitleContent">
          <h2>Health Up Membership</h2>
          <img src={img_icoBanner} alt="Logo-HU" />
          <h3>ลงทะเบียนสมัครสมาชิก</h3>
          {props.step && (
            <div className="tStep">
              <span>
                {props.step.current}/{props.step.max}
              </span>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
export default PageTitleGuest;
