import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPromotion } from "../../../features/PromotionSlice";

const CardPromotion = (props) => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง

  const setDataPromotion = (newData) => {
    const tmpObj = { id: newData.id, data: newData, image: null };
    dispatch(setPromotion(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goto promotion detail page */
  const navigate = useNavigate();

  const handleGotoPromotionDetail = (e) => {
    e.preventDefault();
    // console.log("handleGotoPromotionDetail >>", props.dataPromotion);

    setDataPromotion(props.dataPromotion);

    navigate("/promotion/detail");
  };
  /* End Handle goto promotion detail page */

  // useEffect(() => {
  //   console.log("props", props);
  // }, []);

  const [height, setHeight] = useState(0);
  const eleRef = useRef(null);

  useEffect(() => {
    // console.log(props.index, height, props.curHeight);
    props.setNewHeight(props.index, height);
  }, [height]);

  useLayoutEffect(() => {
    const handleResize = () => {
      setHeight(eleRef.current.offsetHeight);
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="bGroup promotion" ref={eleRef}>
      <a className="bPic" href="#" onClick={handleGotoPromotionDetail}>
        <img src={props.dataPromotion.thumbnailImg} />
      </a>
    </div>
  );
};

export default CardPromotion;
