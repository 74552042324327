import React, { useState, useEffect } from "react";
import moment from "moment";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ModalDisease from "../../../../components/modal/healthservice/main/ModalDisease";
import ModalPill from "../../../../components/modal/healthservice/main/ModalPill";
import ModalSuccess from "../../../../components/modal/healthservice/ModalSuccess";
import {
  createHealthCard,
  getSearchSlot,
  HealthCardForm,
} from "../../../../services/Api/Module/HealthService";
import { branch } from "../../../../services/Api/Module/Customer";
import ModalLoading from "../../../../components/modal/healthservice/ModalLoading";
import ModalConfirm from "../../../../components/modal/healthservice/ModalConfirm";
import ModalError from "../../../../components/modal/healthservice/ModalError";
const HealthServiceUpdate = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);
  /* Handle goto page */
  const navigate = useNavigate();

  const [isAppointmentNeeded, setIsAppointmentNeeded] = useState(false);
  const [dateTime, setDateTime] = useState(moment());
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [pressureTop, setPressureTop] = useState("");
  const [pressureBottom, setPressureBottom] = useState("");
  const [sugar, setSugar] = useState("");
  const [fat, setFat] = useState("");
  const [visceralfat, setVisceralfat] = useState("");
  const [muscle, setMuscle] = useState("");
  const [bone, setBone] = useState("");
  const [nextAppointmentBranch, setNextAppointmentBranch] = useState("");
  const [nextAppointmentDate, setNextAppointmentDate] = useState("");
  const [nextAppointmentPeriod, setNextAppointmentPeriod] = useState("");
  const [nextAppointmentPolicy, setNextAppointmentPolicy] = useState("");

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isDiseaseModalOpen, setIsDiseaseModalOpen] = useState(false);
  const [isPillModalOpen, setIsPillModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [selectedDiseases, setSelectedDiseases] = useState([]);
  const [selectedPill, setSelectedPill] = useState([]);
  const [selectedOtherDiseases, setSelectedOtherDiseases] = useState();
  const [selectedOtherPill, setSelectedOtherPill] = useState();
  const [isSubmit, setIsSubmit] = useState(false);

  const [diseaseAll, setDiseaseAll] = useState([]);
  const [pillAll, setPillAll] = useState([]);
  const [branchAll, setBranchAll] = useState([]);
  const [periodAll, setPeriodAll] = useState([]);

  const [isEmptyData, setIsEmptyData] = useState(true);
  const [selectTimeLabel, setSelectTimeLabel] = useState(
    "เลือกเวลานัดตรวจสุขภาพ"
  );

  const handleRadioChange = (e) => {
    setIsAppointmentNeeded(e.target.value === "yes");
  };
  const handlePolicyCheckbox = (e) => {
    setNextAppointmentPolicy(e.target.checked ? 1 : 0);
  };
  const handleOpenDiseaseModal = () => {
    setIsDiseaseModalOpen(true);
  };
  const handleOpenPillModal = () => {
    setIsPillModalOpen(true);
  };

  const handleCloseDiseaseModal = () => {
    setIsDiseaseModalOpen(false);
  };
  const handleOpenSuccessModal = () => {
    setIsSuccessModalOpen(true);
  };
  const handleConfirmConfirm = async () => {
    setIsConfirmModalOpen(false);
    setIsLoadingModalOpen(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    let payload = {
      customer: stateProfile.id,
      weight: weight,
      height: height,
      sbp: pressureTop,
      dbp: pressureBottom,
      bloodSugar: sugar,
      bodyFat: fat,
      visceralFat: visceralfat,
      muscleMass: muscle,
      boneMass: bone,
      congenitalDisease: selectedDiseases,
      otherDisease: selectedOtherDiseases,
      drugAllergy: selectedPill,
      otherAllergy: selectedOtherPill,
      branch: nextAppointmentBranch,
      isConsent: nextAppointmentPolicy,
    };
    if (isAppointmentNeeded) {
      payload = {
        ...payload,
        isNextAppointment: 1,
        appointmentDate: nextAppointmentDate,
        appointmentStartTime: nextAppointmentPeriod.split(" - ")[0],
        appointmentEndTime: nextAppointmentPeriod.split(" - ")[1],
      };
    } else {
      payload.isNextAppointment = 0;
    }
    fetchPostCreateHealthCard(payload);
  };
  const handleConfirmDiseases = (diseases, other) => {
    console.log("Confirming diseases:", diseases);
    setSelectedDiseases(diseases);
    setSelectedOtherDiseases(other);
    setIsDiseaseModalOpen(false);
  };
  const handleConfirmPill = (pill, other) => {
    console.log("Confirming diseases:", pill);
    setSelectedPill(pill);
    setSelectedOtherPill(other);
    setIsPillModalOpen(false);
  };
  const handleConfirmSuccess = () => {
    setIsSuccessModalOpen(false);
    navigate("/healthservice-catagory");
  };

  const fetchGetHealthCardForm = async (payload) => {
    try {
      const response = await HealthCardForm({ params: payload });
      const resData = response?.data;
      if (resData.resultCode === 20200) {
        if (Object.keys(resData.body.healthInfo).length === 0) {
          setIsEmptyData(true);
        } else {
          setIsEmptyData(false);
        }
        const diseaseIds = resData.body.congenitalDiseaseMap.map(
          (disease) => disease.id
        );
        const pillIds = resData.body.drugAllergyMap.map((pill) => pill.id);
        setWeight(resData.body.healthInfo?.weight || "");
        setHeight(resData.body.healthInfo?.height || "");
        setPressureTop(resData.body.healthInfo?.sbp || "");
        setPressureBottom(resData.body.healthInfo?.dbp || "");
        setSugar(resData.body.healthInfo?.bloodSugar || "");
        setVisceralfat(resData.body.healthInfo?.visceralFat || "");
        setFat(resData.body.healthInfo?.bodyFat || "");
        setBone(resData.body.healthInfo?.boneMass || "");
        setMuscle(resData.body.healthInfo?.muscleMass || "");
        setSelectedDiseases(diseaseIds || []);
        setSelectedPill(pillIds || []);
        setSelectedOtherDiseases(resData.body.healthInfo?.otherDisease || "");
        setSelectedOtherPill(resData.body.healthInfo?.otherAllergy || "");
        setDiseaseAll(resData.body.congenitalDiseaseOption || "");
        setPillAll(resData.body.drugAllergyOption || "");
      }
    } catch (err) {
      console.log("err ", err);
    }
  };
  const fetchPostCreateHealthCard = async (payload) => {
    try {
      const response = await createHealthCard({ data: payload });
      const resData = response?.data;
      if (resData.resultCode === 20200) {
        setIsLoadingModalOpen(false);
        setIsSuccessModalOpen(true);
      } else {
        setIsLoadingModalOpen(false);
        setIsSuccessModalOpen(false);
        setIsErrorModalOpen(true);
      }
    } catch (err) {
      console.log("err ", err);
    }
  };
  const fetchGetSearchSlot = async (payload) => {
    try {
      const response = await getSearchSlot({ params: payload });
      const resData = response?.data;
      setPeriodAll(resData.body);
      if (resData.body.length == 0) {
        setSelectTimeLabel("ไม่พบข้อมูล");
      } else {
        setSelectTimeLabel("เลือกเวลานัดตรวจสุขภาพ");
      }
    } catch (err) {
      console.log("err ", err);
    }
  };
  const fetchGetBranch = async (payload) => {
    try {
      const response = await branch({});
      const resData = response?.data;
      setBranchAll(resData.body);
    } catch (err) {
      console.log("err ", err);
    }
  };

  useEffect(() => {
    if (stateProfile.id === 0) {
      navigate("/");
    }
  }, []);

  const isFormValid = () => {
    const isPressureValid =
      (pressureTop.trim() !== "" && pressureBottom !== "") ||
      (pressureTop.trim() === "" && pressureBottom === "");

    const baseValidation =
      height.trim() !== "" &&
      weight.trim() !== "" &&
      nextAppointmentPolicy == 1 &&
      isPressureValid;

    if (isAppointmentNeeded) {
      return (
        baseValidation &&
        nextAppointmentDate.trim() !== "" &&
        nextAppointmentBranch.trim() !== "" &&
        nextAppointmentPeriod.trim() !== ""
      );
    } else {
      return baseValidation;
    }
  };

  useEffect(() => {
    moment.locale("th"); // Set the locale to Thai
    const timer = setInterval(() => setDateTime(moment()), 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (stateProfile.id === 0) {
      navigate("/");
    }
    let payload = {
      customerId: stateProfile.id,
    };
    fetchGetHealthCardForm(payload);
    fetchGetBranch();
  }, []);

  useEffect(() => {
    if (nextAppointmentBranch && nextAppointmentDate) {
      let payload = {
        branch: nextAppointmentBranch,
        day: moment(nextAppointmentDate).locale("en").format("dddd"),
      };
      fetchGetSearchSlot(payload);
    }
  }, [nextAppointmentBranch, nextAppointmentDate]);

  useEffect(() => {
    // console.log(isFilterSubmit);
  }, [isSubmit]);

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>
      <div id="pageHealthServiceUpdate">
        <main>
          <div className="wrapContent">
            <section id="pHealthServiceUpdate">
              <div className="bHealthDetail">
                <div className="bForm">
                  <p className="tHead">อัปเดตข้อมูลสุขภาพ</p>
                  <div className="bTime">
                    <p className="tBold">วันที่บันทึกข้อมูล :</p>
                    <p>{dateTime.format("DD / MM / YYYY")}</p>
                    <p className="tBold">เวลาที่บันทึกข้อมูล :</p>
                    <p>{dateTime.format("HH.mm")} น.</p>
                  </div>
                  <p className="tHead">กรอกข้อมูลสุขภาพ</p>
                  <div className="control-group validate">
                    <div className="tTitle">ส่วนสูง</div>
                    <input
                      type="text"
                      name="height"
                      value={height}
                      onChange={(e) => {
                        setHeight(e.target.value);
                      }}
                      inputMode="numeric"
                      placeholder="กรอกส่วนสูง"
                    />
                    <label htmlFor="" className="tError">
                      Text Error Input
                    </label>
                  </div>
                  <div className="control-group validate">
                    <div className="tTitle">น้ำหนัก</div>
                    <input
                      type="text"
                      name="weight"
                      value={weight}
                      onChange={(e) => {
                        setWeight(e.target.value);
                      }}
                      inputMode="numeric"
                      placeholder="กรอกน้ำหนัก"
                    />
                    <label htmlFor="" className="tError">
                      Text Error Input
                    </label>
                  </div>
                  <div className="bInline">
                    <p className="sym1">ระดับความดัน</p>
                    <div className="bSelectNumber">
                      <div className="control-group-selectNumber">
                        <input
                          type="text"
                          name="pressureTop"
                          value={pressureTop}
                          onChange={(e) => {
                            setPressureTop(e.target.value);
                          }}
                          inputMode="decimal"
                          placeholder="กรอกตัวบน"
                        />
                        <div className="btnControl">
                          <div
                            className="up"
                            onClick={() =>
                              setPressureTop((prev) => {
                                const currentValue = parseFloat(prev) || 0;
                                return (currentValue + 1).toFixed(2);
                              })
                            }
                          ></div>
                          <div
                            className="down"
                            onClick={() =>
                              setPressureTop((prev) => {
                                const currentValue = parseFloat(prev) || 0;
                                return (currentValue - 1).toFixed(2);
                              })
                            }
                          ></div>
                        </div>
                      </div>
                      <div className="control-group-selectNumber">
                        <input
                          type="text"
                          name="pressureBottom"
                          value={pressureBottom}
                          onChange={(e) => {
                            setPressureBottom(e.target.value);
                          }}
                          inputMode="decimal"
                          placeholder="กรอกตัวล่าง"
                        />
                        <div className="btnControl">
                          <div
                            className="up"
                            onClick={() =>
                              setPressureBottom((prev) => {
                                const currentValue = parseFloat(prev) || 0;
                                return (currentValue + 1).toFixed(2);
                              })
                            }
                          ></div>
                          <div
                            className="down"
                            onClick={() =>
                              setPressureBottom((prev) => {
                                const currentValue = parseFloat(prev) || 0;
                                return (currentValue - 1).toFixed(2);
                              })
                            }
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bInput">
                    <p className="sym2">ระดับน้ำตาล</p>
                    <div className="control-group-selectNumber">
                      <input
                        type="text"
                        name="sugar"
                        value={sugar}
                        onChange={(e) => setSugar(e.target.value)}
                        inputMode="decimal"
                        placeholder="กรอกระดับน้ำตาล"
                      />
                      <div className="btnControl">
                        <div
                          className="up"
                          onClick={() =>
                            setSugar((prev) => {
                              const currentValue = parseFloat(prev) || 0;
                              return (currentValue + 1).toFixed(2);
                            })
                          }
                        ></div>
                        <div
                          className="down"
                          onClick={() =>
                            setSugar((prev) => {
                              const currentValue = parseFloat(prev) || 0;
                              return Math.max(0, currentValue - 1).toFixed(2);
                            })
                          }
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="bInput">
                    <p className="sym3">%ไขมันในร่างกาย</p>
                    <div className="control-group-selectNumber">
                      <input
                        type="text"
                        name="fat"
                        value={fat}
                        onChange={(e) => {
                          setFat(e.target.value);
                        }}
                        inputMode="decimal"
                        placeholder="กรอกเปอร์เซ็นต์ไขมันในร่างกาย"
                      />
                      <div className="btnControl">
                        <div
                          className="up"
                          onClick={() =>
                            setFat((prev) => {
                              const currentValue = parseFloat(prev) || 0;
                              return Math.max(0, currentValue + 1).toFixed(2);
                            })
                          }
                        ></div>
                        <div
                          className="down"
                          onClick={() =>
                            setFat((prev) => {
                              const currentValue = parseFloat(prev) || 0;
                              return Math.max(0, currentValue - 1).toFixed(2);
                            })
                          }
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="bInput">
                    <p className="sym4">ไขมันในช่องท้อง</p>
                    <div className="control-group-selectNumber">
                      <input
                        type="text"
                        name="visceralfat"
                        value={visceralfat}
                        onChange={(e) => setVisceralfat(e.target.value)}
                        inputMode="decimal"
                        placeholder="กรอกไขมันในช่องท้อง"
                      />
                      <div className="btnControl">
                        <div
                          className="up"
                          onClick={() =>
                            setVisceralfat((prev) => {
                              const currentValue = parseFloat(prev) || 0;
                              return Math.max(0, currentValue + 1).toFixed(2);
                            })
                          }
                        ></div>
                        <div
                          className="down"
                          onClick={() =>
                            setVisceralfat((prev) => {
                              const currentValue = parseFloat(prev) || 0;
                              return Math.max(0, currentValue - 1).toFixed(2);
                            })
                          }
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="bInput">
                    <p className="sym5">มวลกล้ามเนื้อ</p>
                    <div className="control-group-selectNumber">
                      <input
                        type="text"
                        name="muscle"
                        value={muscle}
                        onChange={(e) => setMuscle(e.target.value)}
                        inputMode="decimal"
                        placeholder="กรอกมวลกล้ามเนื้อ"
                      />
                      <div className="btnControl">
                        <div
                          className="up"
                          onClick={() =>
                            setMuscle((prev) => {
                              const currentValue = parseFloat(prev) || 0;
                              return Math.max(0, currentValue + 1).toFixed(2);
                            })
                          }
                        ></div>
                        <div
                          className="down"
                          onClick={() =>
                            setMuscle((prev) => {
                              const currentValue = parseFloat(prev) || 0;
                              return Math.max(0, currentValue - 1).toFixed(2);
                            })
                          }
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="bInput">
                    <p className="sym6">มวลกระดูก</p>
                    <div className="control-group-selectNumber">
                      <input
                        type="text"
                        name="bone"
                        value={bone}
                        onChange={(e) => setBone(e.target.value)}
                        inputMode="decimal"
                        placeholder="กรอกมวลกระดูก"
                      />
                      <div className="btnControl">
                        <div
                          className="up"
                          onClick={() =>
                            setBone((prev) => {
                              const currentValue = parseFloat(prev) || 0;
                              return Math.max(0, currentValue + 1).toFixed(2);
                            })
                          }
                        ></div>
                        <div
                          className="down"
                          onClick={() =>
                            setBone((prev) => {
                              const currentValue = parseFloat(prev) || 0;
                              return Math.max(0, currentValue - 1).toFixed(2);
                            })
                          }
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="bInput">
                    <p className="">โรคประจำตัว</p>
                    <div
                      className="InputPopupDetail"
                      onClick={handleOpenDiseaseModal}
                    >
                      {selectedDiseases.length > 0 || selectedOtherDiseases ? (
                        <>
                          {diseaseAll
                            .filter((item) =>
                              selectedDiseases.includes(item.id)
                            )
                            .map((item) => (
                              <div key={item.id} className="choiceSelect">
                                {item.name}
                              </div>
                            ))}
                          {selectedOtherDiseases && (
                            <div className="choiceSelect">
                              {selectedOtherDiseases}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="choiceSelect">เลือกโรคประจำตัว</div>
                      )}
                    </div>
                  </div>
                  <div className="bInput">
                    <p className="">ประวัติแพ้ยา</p>
                    <div
                      className="InputPopupDetail"
                      onClick={handleOpenPillModal}
                    >
                      {selectedPill.length > 0 || selectedOtherPill ? (
                        <>
                          {pillAll
                            .filter((item) => selectedPill.includes(item.id))
                            .map((item) => (
                              <div key={item.id} className="choiceSelect">
                                {item.name}
                              </div>
                            ))}
                          {selectedOtherPill && (
                            <div className="choiceSelect">
                              {selectedOtherPill}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="choiceSelect">
                          เลือกรายชื่อยาที่มีอาการแพ้
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="bInput ">
                    <p>ต้องการนัดหมายตรวจสุขภาพครั้งต่อไปไหม?</p>
                    <div className="control-group">
                      <label className="control control--radio">
                        ต้องการ
                        <input
                          type="radio"
                          name="radio"
                          value="yes"
                          onChange={handleRadioChange}
                          checked={isAppointmentNeeded}
                        />
                        <div className="control__indicator"></div>
                      </label>
                      <label className="control control--radio">
                        ไม่ต้องการ
                        <input
                          type="radio"
                          name="radio"
                          value="no"
                          onChange={handleRadioChange}
                          checked={!isAppointmentNeeded}
                        />
                        <div className="control__indicator"></div>
                      </label>
                    </div>
                  </div>
                </div>
                {isAppointmentNeeded && (
                  <div className="bNextMeeting bForm">
                    <p className="tHead">นัดหมายตรวจสุขภาพครั้งต่อไป</p>
                    <div className="bInput control-group validate">
                      <div className="tTitle">เลือกสาขา</div>
                      <div className="select">
                        <select
                          value={nextAppointmentBranch}
                          onChange={(e) => {
                            setNextAppointmentBranch(e.target.value);
                            setNextAppointmentDate("");
                            setNextAppointmentPeriod("");
                          }}
                        >
                          <option value="">เลือกสาขานัดตรวจสุขภาพ</option>
                          {branchAll.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.nameFe}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="bInput control-group validate">
                      <div className="tTitle">เลือกวันที่นัดตรวจสุขภาพ</div>
                      <input
                        type="date"
                        name="appointmentDate"
                        value={nextAppointmentDate}
                        onChange={(e) => {
                          setNextAppointmentDate(e.target.value);
                          setNextAppointmentPeriod("");
                        }}
                        min={moment().format("YYYY-MM-DD")} // Set minimum date to today
                        required
                      />
                    </div>
                    <div className="bInput control-group validate">
                      <div className="tTitle">
                        เลือกเวลา (ขึ้นกับเวลาเปิดปิดของสถานที่ให้บริการ)
                      </div>
                      <div className="select">
                        <select
                          value={nextAppointmentPeriod}
                          onChange={(e) =>
                            setNextAppointmentPeriod(e.target.value)
                          }
                        >
                          <option value="">{selectTimeLabel}</option>
                          {periodAll.map((item, index) => (
                            <option key={index} value={item.appointmentTime}>
                              {item.appointmentTime}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
                <div className="bNextMeeting">
                  <label className="control control--checkbox">
                    ข้าพเจ้าได้อ่าน{" "}
                    <a
                      onClick={() => {
                        navigate("/privacy-policy");
                      }}
                    >
                      นโยบายความเป็นส่วนตัว
                    </a>
                    {/* <br /> */}
                    และยินยอมรับเงื่อนไขของการเป็นสมาชิก
                    <input
                      type="checkbox"
                      checked={nextAppointmentPolicy === 1}
                      onChange={handlePolicyCheckbox}
                    />
                    <div className="control__indicator"></div>
                  </label>
                </div>
              </div>
            </section>

            <section id="pActionBtn">
              <div className="bBtn">
                <button
                  className="btn outline"
                  onClick={() => navigate("/healthservice-catagory")}
                >
                  ย้อนกลับ
                </button>
                <button
                  className="btn"
                  onClick={() => setIsConfirmModalOpen(true)}
                  disabled={!isFormValid()}
                >
                  ยืนยันข้อมูล
                </button>
              </div>
            </section>
          </div>
          <ModalDisease
            open={isDiseaseModalOpen}
            onClose={handleCloseDiseaseModal}
            onConfirm={handleConfirmDiseases}
            diseases={diseaseAll}
            selected={selectedDiseases}
            other={selectedOtherDiseases}
          />
          <ModalPill
            open={isPillModalOpen}
            onClose={() => setIsPillModalOpen(false)}
            onConfirm={handleConfirmPill}
            pill={pillAll}
            selected={selectedPill}
            other={selectedOtherPill}
          />
          <ModalLoading open={isLoadingModalOpen} />
          <ModalSuccess
            open={isSuccessModalOpen}
            onClose={() => setIsSuccessModalOpen(false)}
            onConfirm={handleConfirmSuccess}
            Header="อัปเดตข้อมูลสุขภาพแล้ว!"
          />
          <ModalConfirm
            open={isConfirmModalOpen}
            onClose={() => setIsConfirmModalOpen(false)}
            onConfirm={handleConfirmConfirm}
            header="ยืนยันการอัปเดตข้อมูล"
            button1="ยืนยัน"
          />
          <ModalError
            open={isErrorModalOpen}
            onClose={() => setIsErrorModalOpen(false)}
            onConfirm={() => setIsErrorModalOpen(false)}
          />
        </main>
      </div>
    </>
  );
};

export default HealthServiceUpdate;
