import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCoupon, resetCoupon } from "../../../../features/CouponSlice";

import { redeemCoupon } from "../../../../services/Api/Module/Customer";

import moment from "moment";

import img_icoTicket from "../../../../assets/img/ci/ico-coupon/ico-ticket.svg";

import ModalConfirmRedeem from "../../../../components/modal/promotion/coupon/ModalConfirmRedeem";
import ModalRedeemFail from "../../../../components/modal/promotion/coupon/ModalRedeemFail";

const CouponDetail = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);
  const stateCoupon = useSelector((state) => state.coupon);

  const setDataCoupon = (redeemData) => {
    const tmpObj = { redeem: redeemData };
    dispatch(setCoupon(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const resetDataCoupon = () => {
    dispatch(resetCoupon()); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goback */
  const navigate = useNavigate();

  const handleGoBack = () => {
    resetDataCoupon();

    navigate("/coupon");
  };
  /* End Handle goback */

  /* Handle change content tab */
  const handleChangeContentTab = () => {
    const _couponTabBtn = document.querySelectorAll("[id^=btnTab]");
    const _couponTabContent = document.querySelectorAll("[id^=tab]");
    // console.log(_couponTabBtn, _couponTabContent);

    for (let i = 0; i < _couponTabBtn.length; i++) {
      _couponTabBtn[i].addEventListener("click", (e) => {
        let _dataTab = e.target.dataset.tab;

        for (let _btn = 0; _btn < _couponTabBtn.length; _btn++) {
          _couponTabBtn[_btn].classList.remove("active");
        }
        document.querySelector(`#btnTab${_dataTab}`).classList.add("active");

        for (
          let _content = 0;
          _content < _couponTabContent.length;
          _content++
        ) {
          _couponTabContent[_content].style.display = "none";
        }
        document.querySelector(`#tab${_dataTab}`).style.display = "block";
      });
    }
  };
  /* End Handle change content tab */

  /* Modal redeem fail */
  const [isModalRedeemFailShow, setIsModalRedeemFailShow] = useState(false);

  const handleOpenModalRedeemFail = () => {
    setIsModalRedeemFailShow(true);
  };

  const handleCloseModalRedeemFail = () => {
    setIsModalRedeemFailShow(false);
  };

  const handleRedeemFail = (submit) => {};
  /* End Modal redeem fail */

  /* Modal confirm redeem */
  const [isModalConfirmRedeemShow, setIsModalConfirmRedeemShow] =
    useState(false);

  const handleOpenModalConfirmRedeem = (e) => {
    e.preventDefault();
    setIsModalConfirmRedeemShow(true);
  };

  const handleCloseModalConfirmRedeem = () => {
    setIsModalConfirmRedeemShow(false);
  };

  const handleConfirmRedeem = (submit) => {
    // console.log(submit);

    if (submit) {
      postRedeemCoupon({
        custId: stateProfile.dataUser.id,
        couponId: stateCoupon.data.id,
      }).then((_rsDataRedeemCoupon) => {
        // console.log(_rsDataRedeemCoupon);

        if (_rsDataRedeemCoupon.status === true) {
          setDataCoupon(_rsDataRedeemCoupon.result);

          navigate("/coupon/redeem");
        } else {
          handleOpenModalRedeemFail();
        }
      });
    }
  };

  const postRedeemCoupon = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await redeemCoupon({ data: payload });
      // console.log("data.body >>", data);
      if (data.resultCode === 20201) {
        const resData = data.body;
        // console.log("postRedeemCoupon resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        console.warn(data);
      }
    } catch (e) {
      console.log("ERR postRedeemCoupon >> ", e);
    }

    return _return;
  };
  /* End Modal confirm redeem */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !!", stateProfile, stateCoupon);

    if (
      stateCoupon.id === 0 &&
      Object.getOwnPropertyNames(stateCoupon.data).length === 0
    ) {
      navigate("/profile");
    } else {
      handleChangeContentTab();
    }
  }, []);
  /* End First event render */

  /* Check image exists */
  const [imageExists, setImageExists] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = stateCoupon.data.thumbnailImg;

    img.onload = () => {
      setImageExists(true);
    };

    // img.onerror = () => {
    //   setImageExists(false);
    // };
  }, [stateCoupon.data.thumbnailImg]);
  /* End Check image exists */

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageCoupon-Details">
        <main>
          <div className="wrapContent">
            <ModalConfirmRedeem
              open={isModalConfirmRedeemShow}
              onClose={handleCloseModalConfirmRedeem}
              submitCallback={handleConfirmRedeem}
            />

            <ModalRedeemFail
              open={isModalRedeemFailShow}
              onClose={handleCloseModalRedeemFail}
              submitCallback={handleRedeemFail}
            />

            <section>
              <div className="bTitle">
                <h1>{stateCoupon.data.name}</h1>
                <p>ยอดซื้อขั้นต่ำ ฿{stateCoupon.data.minTotal}.-</p>
              </div>
            </section>
            <section id="pCouponDetails">
              <div className="bCouponDetails">
                <div className="bContent">
                  <div className="bText">
                    <p>
                      <span>ใช้ได้ตั้งแต่</span>วันนี้ -{" "}
                      {/* {moment(stateCoupon.data.endDate).format("DD/MM/YY")} */}
                      {moment(stateCoupon.data.endDate).format("DD/MM/") +
                        (
                          parseInt(
                            moment(stateCoupon.data.endDate).format("YYYY")
                          ) + 543
                        )
                          .toString()
                          .slice(-2)}
                    </p>
                  </div>
                  <div className="bPic">
                    {imageExists ? (
                      <img src={stateCoupon.data.thumbnailImg} />
                    ) : (
                      <img src={img_icoTicket} />
                    )}
                  </div>
                  <div className="bGroupTab">
                    <div className="bBtnTab">
                      <a className="active" id="btnTab1" data-tab="1">
                        รายละเอียด
                      </a>
                      <a className="" id="btnTab2" data-tab="2">
                        เงื่อนไข
                      </a>
                      <a className="" id="btnTab3" data-tab="3">
                        สาขาที่ร่วม
                      </a>
                    </div>
                    <div
                      className="bTabContent"
                      id="tab1"
                      style={{
                        display: "block",
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          stateCoupon.data.description &&
                          stateCoupon.data.description.replaceAll(
                            "className",
                            "class"
                          ),
                      }}
                    ></div>
                    <div
                      className="bTabContent"
                      id="tab2"
                      style={{
                        display: "none",
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          stateCoupon.data.conditions &&
                          stateCoupon.data.conditions.replaceAll(
                            "className",
                            "class"
                          ),
                      }}
                    ></div>
                    <div
                      className="bTabContent"
                      id="tab3"
                      style={{
                        display: "none",
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          stateCoupon.data.branchDetail &&
                          stateCoupon.data.branchDetail.replaceAll(
                            "className",
                            "class"
                          ),
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </section>

            <section id="pActionBtn">
              <div className="bBtn">
                <button className="btn outline" onClick={handleGoBack}>
                  ย้อนกลับ
                </button>
                {Object.getOwnPropertyNames(stateCoupon.redeem).length ===
                  0 && (
                  <button
                    className="btn btnConfirm"
                    onClick={handleOpenModalConfirmRedeem}
                  >
                    แลก {stateCoupon.data.point} คะแนน
                  </button>
                )}
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default CouponDetail;
