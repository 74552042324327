import { createSlice } from "@reduxjs/toolkit";

const defaultData = {
  id: 0,
  dataUser: {},
  currentPage: null,
  prevPage: null,
  dataFrom: null
};

export const RegisterSlice = createSlice({
  name: "Register",
  initialState: defaultData,
  reducers: {
    setRegister: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        // console.log(`${key}: ${value}`);
        state[key] = value;
      }
    },
    resetRegister: () => defaultData,
  },
});

// Action creators are generated for each case reducer function
export const { setRegister, resetRegister } = RegisterSlice.actions;

export default RegisterSlice.reducer;
