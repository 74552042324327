import { useEffect, useState } from "react";

import jBox from "jbox";
import $ from "jquery";

const ModalEditProfileSuccess = ({ open = false, onClose = () => {} }) => {
  const [modal, setModal] = useState(null);

  const openModal = () => {
    modal.open();
  };

  const closeModal = () => {
    modal.close();
    onClose();
  };

  const listenerCloseModal = () => {
    const btnCloseModal = document.getElementById("btnCloseModal");
    btnCloseModal.addEventListener("click", () => {
      closeModal();
    });
  };

  /* First event render */
  useEffect(() => {
    const initModal = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 375,
      content: $("#ModalConfirm"),
    });
    setModal(initModal);

    return () => {
      initModal.destroy();
    };
  }, []);
  /* End First event render */

  useEffect(() => {
    // console.log("modalQrCode useEffect2!! >>", modalQrCode, open);

    if (modal && open) {
      openModal();
      listenerCloseModal();
      // setTimeout(() => listenerCloseModal(), 100)
    }
  }, [modal, open]);

  return (
    <div className="bModal bCard editSuccess" id="ModalConfirm">
      <div className="bInner">
        <h3>แก้ไขข้อมูลสำเร็จ</h3>
        <p>ข้อมูลของคุณได้รับการแก้ไขเรียบร้อยแล้ว</p>
        <div className="bBtn">
          <a className="btn outline" id="btnCloseModal">สำเร็จ</a>
        </div>
      </div>
    </div>
  );
};

export default ModalEditProfileSuccess;