import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "../../../../features/ProfileSlice";
import { PatternFormat } from "react-number-format";
import {
  checkDupMobileNo,
  updateCustomer,
  branch as selectBranch,
} from "../../../../services/Api/Module/Customer";

import Helper, {
  // convertDateFormat,
  convertMobileFormat,
  breakKeyDownEnter,
} from "../../../../services/helper";

import moment from "moment";

import img_icoLogo from "../../../../assets/img/ci/icon-policy/ico-logo.svg";

import ModalEditProfileSuccess from "../../../../components/modal/account/profile/edit/ModalEditProfileSuccess";

const EditProfile = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);

  const setDataProfile = (newData, updData = {}) => {
    const tmpObj = { dataUser: newData, updData: updData };
    dispatch(setProfile(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* Form */
  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    resetField,
    formState: { errors, isValid },
    setError,
    clearErrors,
    getFieldState,
  } = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      gender: "",
      mobileNo: "",
      branch: "",
      isConsent: false,
    },
  });

  // console.log("isValid: ", isValid);
  const [formIsValid, setFormIsValid] = useState(false);

  const handleCheckOnChange = async () => {
    const _field = getValues();
    let _invalidState = true,
      _invalidFields = [];

    for (const key in _field) {
      const val = _field[key];
      // console.log(key, typeof val, val);

      let _invalid = getFieldState(key).invalid;
      if (val === "" || val === false || val === null) {
        _invalid = true;
      }

      if (key === "mobileNo") {
        let _value = val;
        _value = _value.replaceAll("-", "");
        _value = _value.trim();

        // console.log(_value, _value.length);

        if (_value.length === 10) {
          // console.log(stateProfile.dataUser.mobileNo, _value);

          clearErrors("mobileNo");

          if (_value !== stateProfile.dataUser.mobileNo) {
            // console.log("handleCheckMobileNoExists", await handleCheckMobileNoExists(_value));
            _invalid = await handleCheckMobileNoExists(_value);
          }
        } else {
          _invalid = true;
        }
      }

      if (key === "isConsent") {
        let _value = val;
        // console.log(_value);

        if (_value || !_value) {
          _invalid = false;
        }
      }

      _invalidFields.push(_invalid);
    }

    _invalidState = _invalidFields.includes(true);

    if (_invalidState === true) {
      setFormIsValid(false);
    } else {
      setFormIsValid(true);
    }

    // console.log("onChange", _invalidFields, _invalidState);
  };

  useEffect(() => {
    // console.log(formIsValid);
  }, [formIsValid]);
  /* End Form */

  /* Check mobile nubmer exists real-time on server-side  */
  const handleCheckMobileNoExists = async (mobileNo) => {
    // console.log(mobileNo);

    let _return = false;

    await getCheckDupMobileNo({
      mobileNo: mobileNo,
      // id: stateProfile.id,
      custCode: stateProfile.custCode,
    }).then((_rsData) => {
      // console.log(_rsData);

      if (_rsData.status === true) {
        setError(
          "mobileNo",
          {
            type: "manual",
            message: `เบอร์โทรศัพท์นี้มีผู้ใช้งานแล้ว`,
          },
          {
            shouldFocus: true,
          }
        );

        _return = true;
      }
    });

    return _return;
  };

  const getCheckDupMobileNo = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await checkDupMobileNo({ params: payload });
      if (data.resultCode === 40301) {
        _return.status = true;
      }
    } catch (e) {
      console.log("ERR getCheckDupMobileNo >> ", e);
    }

    return _return;
  };
  /* End Check mobile nubmer exists real-time on server-side  */

  /* Modal edit profile success */
  const [isModalShow, setIsModalShow] = useState(false);

  const handleCloseModal = () => {
    setIsModalShow(false);

    setTimeout(() => navigate("/profile"), 200);
  };
  /* End Modal edit profile success */

  /* Handle click submit form */
  const onSubmit = (data) => {
    // console.log("onSubmit >>", data);

    data.mobileNo = convertMobileFormat(data.mobileNo);
    // console.log(data.mobileNo, (data.mobileNo !== stateProfile.dataUser.mobileNo));

    let payload = {
      id: stateProfile.id,
      custCode: stateProfile.custCode,
      ...data,
    };
    // console.log(payload);

    if (data.mobileNo !== stateProfile.dataUser.mobileNo) {
      setDataProfile(stateProfile.dataUser, data);

      navigate("/edit-profile/otp");
    } else {
      postUpdateCustomer(payload).then((_rsDataUpdateCustomer) => {
        // console.log(_rsDataUpdateCustomer);

        if (_rsDataUpdateCustomer.status === true) {
          setDataProfile({
            ...stateProfile.dataUser,
            ...data,
          });

          setIsModalShow(true);
        }
      });
    }
  };

  const postUpdateCustomer = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await updateCustomer({ data: payload });
      // console.log("data.body >>", data);
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("postUpdateCustomer resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        console.warn(data);
      }
    } catch (e) {
      console.log("ERR postUpdateCustomer >> ", e);
    }

    return _return;
  };
  /* End Handle click submit form */

  /* First event render */
  const [branchList, setBranchList] = useState([]);

  const getBranch = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await selectBranch({ data: payload });
      // console.log("data.body >>", data);
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getBranch resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        console.warn(data);
      }
    } catch (e) {
      console.log("ERR getBranch >> ", e);
    }

    return _return;
  };

  useEffect(() => {
    // console.log("useEffect !", stateProfile.dataUser);
    // console.log("useEffect !", stateProfile.dataUser.branch);

    const { dataUser } = stateProfile;

    if (stateProfile.id === 0 || Object.keys(dataUser).length === 0) {
      navigate("/");
    } else {
      getBranch({}).then((_rsDataBranch) => {
        // console.log(_rsDataBranch);

        if (
          _rsDataBranch.status === true &&
          _rsDataBranch.result.length !== 0
        ) {
          setBranchList(_rsDataBranch.result);
        }
      });

      if (Object.keys(dataUser).length !== 0) {
        setValue("firstname", dataUser.firstname);
        setValue("lastname", dataUser.lastname);
        setValue("gender", dataUser.gender);
        setValue("mobileNo", convertMobileFormat(dataUser.mobileNo, "-"));
        setValue("branch", dataUser.branch !== null ? dataUser.branch : "1");
        setValue("isConsent", dataUser.isConsent, { shouldValidate: false });

        let _chkFormIsValid = true;
        const _chkField = ["firstname", "lastname", "gender", "mobileNo"];
        Object.entries(dataUser).forEach(([key, val]) => {
          if (_chkField.includes(key)) {
            if (val === "" || val === false || val === null) {
              _chkFormIsValid = false;
            }
          }
        });

        if (_chkFormIsValid === true) {
          setFormIsValid(true);
        }
      }
    }
  }, []);
  /* End First event render */

  useEffect(() => {
    // console.log(branchList);
  }, [branchList]);

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageEditProfile">
        <main>
          <div className="wrapContent">
            <ModalEditProfileSuccess
              open={isModalShow}
              onClose={handleCloseModal}
            />

            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => breakKeyDownEnter(e)}
            >
              <section id="pEditProfile">
                <div className="bEditProfile">
                  <div className="bannerTiile">
                    <img src={img_icoLogo} alt="" />
                  </div>

                  <div className="bForm">
                    <h3>แก้ไขข้อมูลส่วนตัว</h3>
                    <div className="bRow">
                      <div className="control-group">
                        <div className="tTitle">รหัสสมาชิก</div>
                        <input
                          disabled="disabled"
                          type="text"
                          name=""
                          placeholder={stateProfile.custCode}
                        />
                      </div>
                    </div>
                    <div className="bRow">
                      <div className="control-group">
                        <div className="tTitle">ประเภทสมาชิก</div>
                        <input
                          disabled="disabled"
                          type="text"
                          name=""
                          placeholder="สมาชิก HUG Club"
                        />
                      </div>
                    </div>
                    <div className="bRow">
                      <div className="control-group">
                        <div className="tTitle">วัน/เดือน/ปีเกิด</div>
                        <input
                          disabled="disabled"
                          type="text"
                          name=""
                          // placeholder={
                          //   stateProfile.dataUser.birthDate
                          //     ? convertDateFormat(
                          //         stateProfile.dataUser.birthDate,
                          //         "d/m/y"
                          //       )
                          //     : ""
                          // }
                          placeholder={
                            stateProfile.dataUser.birthDate &&
                            moment(stateProfile.dataUser.birthDate).format(
                              "DD/MM/"
                            ) +
                              (parseInt(
                                moment(stateProfile.dataUser.birthDate).format(
                                  "YYYY"
                                )
                              ) +
                                543)
                          }
                        />
                      </div>
                    </div>
                    <div className="bRow">
                      <div
                        className={`control-group ${
                          errors.firstname ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">ชื่อ</div>
                        <Controller
                          name="firstname"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `กรุณากรอกชื่อ`,
                          }}
                          render={({ field: { onChange, ...field } }) => (
                            <input
                              {...field}
                              type="text"
                              placeholder={`กรอกชื่อ`}
                              onInput={Helper.FNFORM.handleCharOnly}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                handleCheckOnChange();
                              }}
                            />
                          )}
                        />
                        {errors.firstname && (
                          <label htmlFor="" className="tError">
                            {errors.firstname.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="bRow ">
                      <div
                        className={`control-group ${
                          errors.lastname ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">นามสกุล</div>
                        <Controller
                          name="lastname"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `กรุณากรอกนามสกุล`,
                          }}
                          render={({ field: { onChange, ...field } }) => (
                            <input
                              {...field}
                              type="text"
                              placeholder={`กรอกนามสกุล`}
                              onInput={Helper.FNFORM.handleCharOnly}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                handleCheckOnChange();
                              }}
                            />
                          )}
                        />
                        {errors.lastname && (
                          <label htmlFor="" className="tError">
                            {errors.lastname.message}
                          </label>
                        )}
                      </div>
                    </div>

                    <div
                      className={`control-group margine-button ${
                        errors.gender ? "error" : ""
                      }`}
                    >
                      <div className="tTitle">เพศ</div>
                      <Controller
                        name="gender"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: `กรุณาเลือกเพศ`,
                        }}
                        render={({ field }) => (
                          <div className="control-radio">
                            <label className="control control--radio">
                              ชาย
                              <input
                                {...field}
                                type="radio"
                                value="m"
                                checked={field.value === "m"}
                              />
                              <div className="control__indicator"></div>
                            </label>
                            <label className="control control--radio">
                              หญิง
                              <input
                                {...field}
                                type="radio"
                                value="f"
                                checked={field.value === "f"}
                              />
                              <div className="control__indicator"></div>
                            </label>
                            {/* <label className="control control--radio">
                              ไม่ระบุ
                              <input
                                {...field}
                                type="radio"
                                value="n/a"
                                checked={field.value === "n/a"}
                              />
                              <div className="control__indicator"></div>
                            </label> */}
                          </div>
                        )}
                      />
                      {errors.gender && (
                        <label htmlFor="" className="tError">
                          {errors.gender.message}
                        </label>
                      )}
                    </div>

                    <div className="bRow">
                      <div
                        className={`control-group ${
                          errors.mobileNo ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">เบอร์โทรศัพท์</div>
                        <Controller
                          name="mobileNo"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `กรุณากรอกเบอร์โทรศัพท์`,
                            pattern: {
                              value: /^\d{3}-\d{3}-\d{4}$/, // /^\d{10}$/,
                              message: `กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง`,
                            },
                          }}
                          render={({ field: { name, value, onChange } }) => (
                            <PatternFormat
                              name={name}
                              value={value}
                              displayType="input"
                              format="###-###-####"
                              placeholder={`กรอกเบอร์โทรศัพท์`}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                handleCheckOnChange();
                              }}
                            />
                          )}
                        />
                        {errors.mobileNo && (
                          <label htmlFor="" className="tError">
                            {errors.mobileNo.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div
                      className={`control-group ${
                        errors.branch ? "error" : ""
                      }`}
                    >
                      <div className="tTitle">สาขาใกล้ฉัน</div>
                      <Controller
                        name="branch"
                        control={control}
                        rules={{
                          required: `กรุณาเลือกสาขา`,
                        }}
                        render={({ field }) => (
                          <div className="select">
                            <select {...field}>
                              {branchList &&
                                branchList.map((item, index) => (
                                  <option key={index + 1} value={item.id}>
                                    {item.nameFe}
                                  </option>
                                ))}
                            </select>
                          </div>
                        )}
                      />
                      {errors.branch && (
                        <label htmlFor="" className="tError">
                          {errors.branch.message}
                        </label>
                      )}
                    </div>
                    <div className="bRow">
                      <div
                        className={`control-group pdpa pdpa-edit ${
                          errors.isConsent ? "error" : ""
                        }`}
                      >
                        <label className="control control--checkbox">
                          <p>ยอมรับนโยบายความเป็นส่วนตัว</p>
                          <Controller
                            name="isConsent"
                            control={control}
                            defaultValue=""
                            // rules={{
                            //   required: `กรุณายอมรับเงื่อนไข`,
                            // }}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="checkbox"
                                checked={watch("isConsent")}
                                onChange={(e) => {
                                  field.onChange(e.target.checked);
                                  handleCheckOnChange();
                                }}
                              />
                            )}
                          />
                          <div className="control__indicator"></div>
                        </label>
                        {errors.isConsent && (
                          <label htmlFor="" className="tError">
                            {errors.isConsent.message}
                          </label>
                        )}
                        <a
                          className="tLink"
                          href="#"
                          onClick={handleGotoPage}
                          data-page="privacy-policy"
                        >
                          อ่านรายละเอียดนโยบายความเป็นส่วนตัว
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section id="pActionBtn">
                <div className="bBtn">
                  <button
                    className="btn outline"
                    onClick={handleGotoPage}
                    data-page="profile"
                  >
                    ย้อนกลับ
                  </button>
                  <button
                    className="btn"
                    type="submit"
                    disabled={formIsValid ? false : true}
                  >
                    ยืนยัน
                  </button>
                </div>
              </section>
            </form>
          </div>
        </main>
      </div>
    </>
  );
};

export default EditProfile;
