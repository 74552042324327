// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("sri_sury_wongse_bold-webfont.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("sri_sury_wongse_bold-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("sri_sury_wongse_bold-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("sri_sury_wongse_bold-webfont.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("sri_sury_wongse_bold-webfont.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "#sri_sury_wongseregular" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 1, 2023 */



@font-face {
    font-family: 'sri_sury_wong';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('embedded-opentype'),
         url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff2'),
         url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff'),
         url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('truetype'),
         url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('svg');
    font-weight: normal;
    font-style: normal;

}
`, "",{"version":3,"sources":["webpack://./src/assets/font/font.css"],"names":[],"mappings":"AAAA,mFAAmF;;;;AAInF;IACI,4BAA4B;IAC5B,4CAA4C;IAC5C;;;;8DAIiF;IACjF,mBAAmB;IACnB,kBAAkB;;AAEtB","sourcesContent":["/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 1, 2023 */\n\n\n\n@font-face {\n    font-family: 'sri_sury_wong';\n    src: url('sri_sury_wongse_bold-webfont.eot');\n    src: url('sri_sury_wongse_bold-webfont.eot?#iefix') format('embedded-opentype'),\n         url('sri_sury_wongse_bold-webfont.woff2') format('woff2'),\n         url('sri_sury_wongse_bold-webfont.woff') format('woff'),\n         url('sri_sury_wongse_bold-webfont.ttf') format('truetype'),\n         url('sri_sury_wongse_bold-webfont.svg#sri_sury_wongseregular') format('svg');\n    font-weight: normal;\n    font-style: normal;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
