import React, { useEffect, useState, useLayoutEffect, useRef } from "react";

const CardEarnPointHistory = (props) => {
  // useEffect(() => {
  //   // console.log("props", props);
  // }, []);

  const [height, setHeight] = useState(0);
  const eleRef = useRef(null);

  useEffect(() => {
    // console.log(props.index, height, props.curHeight);
    props.setNewHeight(props.index, height);
  }, [height]);

  useLayoutEffect(() => {
    const handleResize = () => {
      setHeight(eleRef.current.offsetHeight);
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <article ref={eleRef} data-year={props.year} data-month={props.month}>
      <div className="bHead">
        <p>{props.transactionNo}</p>
        <p>{props.createdDateTh}</p>
      </div>
      <div className="bDetails">
        <div className="bRow text">
          <p>สาขา :</p>
          <p className="right textEnd">{props.branch}</p>
        </div>
        <div className="bRow price">
          <p>ยอดใช้จ่ายทั้งหมด :</p>
          <p>
            {props.totalPrice && parseInt(props.totalPrice).toLocaleString()}
          </p>
        </div>
        <div className="bRow">
          <p>คะแนนที่ได้รับ :</p>
          <p>{props.point && parseInt(props.point).toLocaleString()} คะแนน</p>
        </div>
        <div className="bRow ">
          <p>คะแนนหมดอายุ :</p>
          <p>{props.expiredAtTh}</p>
        </div>
      </div>
    </article>
  );
};

export default CardEarnPointHistory;
