import { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";

const ModalSuccess = ({
  open = false,
  onClose = () => {},
  onConfirm = () => {},
  Header = ""
}) => {

  return (
    <Dialog
      visible={open}
      style={{ width: "325px" }}
      onHide={() => onClose()}
      draggable={false}
      resizable={false}
      closable={false}
    >
      <div className="bModal bCard" id="modalUpdateSuccess">
        <h2>{Header}</h2>
        <p>
          {" "}
          ที่ Health up เราห่วงใย
          <br />
          และหวังให้คุณมีสุขภาพดี
        </p>

        <button className="btn btnCloseModal" onClick={onConfirm}>
          ตกลง
        </button>
      </div>
    </Dialog>
  );
};

export default ModalSuccess;
