import { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";

const ModalConfirm = ({
  open = false,
  onClose = () => {},
  onConfirm = () => {},
  header = "",
  body1 = "",
  body2 = "",
  body3 = "",
  button1 = "",
}) => {
  return (
    <Dialog
      visible={open}
      style={{ width: "325px" }}
      onHide={() => onClose()}
      draggable={false}
      resizable={false}
      closable={false}
    >
      <div className="bModal bCard" id="modalUpdateSuccess">
        <h2>{header}</h2>
        <p>
          {" "}
          {body1 == ""? "": <div>{body1}</div>}
          {body2 == ""? "" : (
            <>
              <div>{body2}</div>
            </>
          )}
          {body3 == "" ? "" : (
            <>
              <br />
              <div>{body3}</div>
              <br />
            </>
          )}
        </p>
        <button className="btn btnCloseModal" onClick={onConfirm}>
          {button1}
        </button>
        <button className="btn outline" onClick={onClose}>
          ยกเลิก
        </button>
      </div>
    </Dialog>
  );
};

export default ModalConfirm;
