import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import $ from "jquery";
import jBox from "jbox";

const ModalRedeemFail = ({
  open = false,
  onClose = () => {},
  submitCallback = () => {},
}) => {
  const [modalRedeemFail, setModalRedeemFail] = useState(null);
  const [clickClose, setClickClose] = useState(false); //block twic click close
  const [clickSubmit, setClickSubmit] = useState(false); //block twic click submit

  const openModal = () => {
    modalRedeemFail.open();
  };

  const closeModal = () => {
    modalRedeemFail.close();
    onClose();
  };

  const listenerClose = () => {
    if (!clickClose) {
      const btnCancel = document.getElementById("btnCloseRedeemFail");
      btnCancel.addEventListener("click", () => {
        closeModal();
        setClickClose(true);
      });
    }
  };

  useEffect(() => {}, [clickClose]);

  // const listenerConfirm = () => {
  //   if (!clickSubmit) {
  //     const btnConfirm = document.getElementById("btnConfirm");
  //     btnConfirm.addEventListener("click", () => {
  //       submitCallback(true);
  //       closeModal();
  //       setClickSubmit(true);
  //     });
  //   }
  // };

  /* First event render */
  useEffect(() => {
    const initModal = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 375,
      content: $("#pointError"),
    });
    setModalRedeemFail(initModal);

    return () => {
      initModal.destroy();
    };
  }, []);
  /* End First event render */

  useEffect(() => {
    // console.log("modal useEffect2!! >>", modalRedeemFail, open);

    if (modalRedeemFail && open) {
      openModal();

      listenerClose();
      // listenerConfirm();
    }
  }, [modalRedeemFail, open]);

  return (
    <div className="bModal bCard" id="pointError">
      <div className="bInner">
        <div className="tContent">
          <h3>คะแนนไม่เพียงพอต่อการแลก</h3>
        </div>
        <p>
          คะแนนของคุณไม่เพียงพอ
          <br />
          กรุณาสะสมคะแนนเพิ่มเติม
          <br />
        </p>
        <div className="bBtn">
          <a className="btn outline btnCloseModalError" id="btnCloseRedeemFail">ย้อนกลับ</a>
        </div>
      </div>
    </div>
  );
};

export default ModalRedeemFail;
