import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRegister } from "../../features/RegisterSlice";
import { useLiff } from "react-liff";

import PageTitleGuest from "../../layouts/inc/PageTitleGuest";

// import useTranslations from "../../i18n/useTranslations";

const Home = () => {
  let { liff } = useLiff();

  // const { t } = useTranslations(); // แปลภาษา

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  // const stateRegister = useSelector((state) => state.register); // ไว้ดึง state
  // const stateProfile = useSelector((state) => state.profile);

  /* Set state Register {currentPage} on goNext */
  const navigate = useNavigate();
  const [page, setPage] = useState("home");

  const PageRoute = {
    "auth-user": "/auth-user",
    register: "/register",
  };

  const setCurrentPage = (currentPage) => {
    const tmpObj = { currentPage: currentPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const setPrevPage = (prevPage) => {
    const tmpObj = { prevPage: prevPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const goNext = () => {
    setCurrentPage(page);
    navigate(PageRoute[page]);
  };
  /* End Set state Register {currentPage} on goNext */

  /* Handle close line liff */
  const handleCloseLiff = () => {
    liff.closeWindow();
  };
  /* End Handle close line liff */

  // useEffect(() => {
  // //   console.log(stateProfile);

  // //   if (stateProfile.lineUid !== null && stateProfile.custCode !== null) {
  // //     navigate("/profile");
  // //   }
  // }, []);

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageRegister">
        <main>
          <div className="wrapContent">
            <PageTitleGuest />

            <section id="pRegisterMain">
              <div className="bRegister">
                <h3>กรุณาเลือกวิธีการลงทะเบียน</h3>
                <div className="bBtnType">
                  <div className="control-group imgRadio">
                    <label className="control control--radio verify">
                      <input
                        type="radio"
                        name="user-type"
                        checked={page === "auth-user"}
                        onChange={() => {
                          setPrevPage(page);
                          setPage("auth-user");
                        }}
                      />
                      <div className="control__indicator"></div>
                    </label>
                    <label className="control control--radio new">
                      <input
                        type="radio"
                        name="user-type"
                        checked={page === "register"}
                        onChange={() => {
                          setPrevPage(page);
                          setPage("register");
                        }}
                      />
                      <div className="control__indicator"></div>
                    </label>
                  </div>
                </div>
                <p>
                  สำหรับท่านที่เคยสมัครเป็นสมาชิก Health Up แล้ว
                  <br />
                  ให้เลือก”ยืนยันตัวตน” เพื่อทำการยืนยันสมาชิก
                </p>
              </div>
            </section>
            <section id="pActionBtn">
              <div className="bBtn">
                <button className="btn outline" onClick={handleCloseLiff}>
                  ย้อนกลับ
                </button>
                <button
                  className="btn"
                  onClick={goNext}
                  disabled={!page || page === "home"}
                >
                  ต่อไป
                </button>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default Home;
