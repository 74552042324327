import React, { useEffect, useState } from "react";

import $ from "jquery";
import jBox from "jbox";

const ModalPointHistoryFilter = ({
  open = false,
  onClose = () => {},
  maxYear = "",
  minYear = "",
  submitCallback = () => {},
}) => {
  /* Set select option */
  let optionsYear = [];

  if (minYear !== "" && maxYear !== "") {
    for (let i = maxYear; i >= minYear; i--) {
      optionsYear.push({
        value: i,
        label: i,
      });
    }
  }
  // console.log(optionsYear);
  /* End Set select option */

  const [modalFilter, setModalFilter] = useState(null);
  const [submitFilter, setSubmitFilter] = useState(false);

  const openModal = () => {
    modalFilter.open();
  };

  const closeModal = () => {
    modalFilter.close();
    onClose();
  };

  const listenerCloseModal = () => {
    const btnCloseModalFilter = document.getElementById("btnCloseModalFilter");
    btnCloseModalFilter.addEventListener("click", () => {
      closeModal();
    });
  };

  const [valueYear, setValueYear] = useState("");
  const [valueMonth, setValueMonth] = useState("");
  const [dataFilter, setDataFilter] = useState({});

  const listenerResetFilter = () => {
    const btnResetFilter = document.getElementById("btnResetFilter");
    btnResetFilter.addEventListener("click", () => {
      let _eleYear = document.getElementById("selectYear");
      let _eleMonth = document.getElementById("selectMonth");

      _eleYear.value = "";
      _eleMonth.value = "";

      // clearStateFilter();
    });
  };

  const listenerSubmitFilter = () => {
    const btnSubmitFilter = document.getElementById("btnSubmitFilter");
    btnSubmitFilter.addEventListener("click", () => {
      const _eleYear = document.getElementById("selectYear");
      const _eleMonth = document.getElementById("selectMonth");

      setValueYear(_eleYear.value);
      setValueMonth(_eleMonth.value);

      setSubmitFilter(true);

      closeModal();
    });
  };

  const clearStateFilter = () => {
    // console.log("clearStateFilter");
    setValueYear("");
    setValueMonth("");
  };

  const handleOnSelectChange = (e) => {
    // console.log(e.target.value);
  };

  /* First event render */
  useEffect(() => {
    const initModalFilter = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 375,
      content: $("#ModalFilter"),
      // onOpen: () => {
      //   console.log('open');
      // },
      // onOpenComplete: () => {
      //   console.log('openComplete');
      // },
      // onClose: () => {
      //   console.log('close');
      // },
      // onCloseComplete: () => {
      //   console.log('closeComplete');
      // }
    });
    setModalFilter(initModalFilter);

    return () => {
      initModalFilter.destroy();
    };
  }, []);
  /* End First event render */

  useEffect(() => {
    // console.log("modalFilter useEffect2!! >>", modalFilter, open);

    if (modalFilter !== null && open) {
      openModal();
      listenerCloseModal();
      // setTimeout(() => listenerCloseModal(), 100)

      listenerResetFilter();
      listenerSubmitFilter();
    }
  }, [modalFilter, open]);

  useEffect(() => {
    setDataFilter({
      year: valueYear,
      month: valueMonth,
    });
  }, [valueYear, valueMonth]);

  useEffect(() => {
    submitCallback(dataFilter, submitFilter);
  }, [dataFilter]);

  return (
    <div className="bModal bCard" id="ModalFilter">
      <div className="bInner">
        <div className="bTitle">
          <span>กรองข้อมูลโดย</span>{" "}
        </div>
        <div className="bFilterForm">
          <div className="bForm warranty">
            <div className="bRow">
              <div className="control-group">
                {/* <div className="tTitle">ปี (ค.ศ.)</div> */}
                <div className="tTitle">ปี</div>
                <div className="select">
                  <select name="year" id="selectYear">
                    <option value="">ทั้งหมด</option>
                    {optionsYear.map(({ value, label }, index) => (
                      <option key={index + 1} value={value}>
                        {/* {label} */}
                        {parseInt(label) + 543}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="bRow">
              <div className="control-group">
                <div className="tTitle">เดือน</div>
                <div className="select">
                  <select name="month" id="selectMonth">
                    <option value="">ทั้งหมด</option>
                    <option value="01">มกราคม</option>
                    <option value="02">กุมภาพันธ์</option>
                    <option value="03">มีนาคม</option>
                    <option value="04">เมษายน</option>
                    <option value="05">พฤษภาคม</option>
                    <option value="06">มิถุนายน</option>
                    <option value="07">กรกฎาคม</option>
                    <option value="08">สิงหาคม</option>
                    <option value="09">กันยายน</option>
                    <option value="10">ตุลาคม</option>
                    <option value="11">พฤศจิกายน</option>
                    <option value="12">ธันวาคม</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="bBtn">
            <button className="btn" id="btnSubmitFilter">
              ยืนยัน
            </button>
            <button className="btn outline btnClose" id="btnResetFilter">
              รีเซ็ท
            </button>
          </div>
        </div>
      </div>
      <a className="btnCloseModalIcon" id="btnCloseModalFilter"></a>
    </div>
  );
};

export default ModalPointHistoryFilter;
