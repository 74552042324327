import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSpring, animated } from "react-spring";

import { setProfile } from "../../../features/ProfileSlice";
import { custProfile, tierConfig } from "../../../services/Api/Module/Customer";

// import moment from "moment";

// import PageTitleMember from "../../../layouts/inc/PageTitleMember";

// import MenuMember from "../../../components/menu/MenuMember";

import Helper from "../../../services/helper";

import img_picTierLogo from "../../../assets/img/ci/pic-tier-logo.png";

const Tier = () => {
  const stateProfile = useSelector((state) => state.profile);

  /* Handle goto page */
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/profile");
  };
  /* End Handle goto page */

  /* Handle change tier tab */
  const handleChangeTierTab = () => {
    const _tierTabBtn = document.querySelectorAll("[id^=btnTab]");
    const _tierTabContent = document.querySelectorAll("[id^=tab]");
    // console.log(_tierTabBtn, _tierTabContent);

    for (let i = 0; i < _tierTabBtn.length; i++) {
      _tierTabBtn[i].addEventListener("click", (e) => {
        let _dataTab = e.target.dataset.tab;

        for (let _btn = 0; _btn < _tierTabBtn.length; _btn++) {
          _tierTabBtn[_btn].classList.remove("active");
        }
        document.querySelector(`#btnTab${_dataTab}`).classList.add("active");

        for (let _content = 0; _content < _tierTabContent.length; _content++) {
          _tierTabContent[_content].style.display = "none";
        }
        document.querySelector(`#tab${_dataTab}`).style.display = "flex";
      });
    }
  };
  /* End Handle change tier tab */

  /* First event render */
  const [sysTierConfig, setSysTierConfig] = useState(null);
  const [tierUser, setTierUser] = useState(stateProfile.dataUser.tier);

  const getTierConfig = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await tierConfig({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getTierConfig resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getTierConfig >> ", e);
    }

    return _return;
  };

  useEffect(() => {
    // console.log("useEffect !", stateProfile);

    if (
      Object.keys(stateProfile.dataUser).length !== 0 &&
      stateProfile.dataUser !== null
    ) {
      getTierConfig({}).then((_rsTierConfig) => {
        // console.log("_rsTierConfig >>", _rsTierConfig);

        setSysTierConfig(_rsTierConfig.result);
      });
    } else {
      navigate("/profile");
    }
  }, []);
  /* End First event render */

  const [pointPercent, setPointPercent] = useState(0);

  useEffect(() => {
    // console.log(sysTierConfig);

    if (sysTierConfig !== null) {
      handleChangeTierTab();
    }
  }, [sysTierConfig]);

  const renderPointPercent = (index) => {
    const _percent =
      ((stateProfile.dataUser.tierTotalBuy
        ? parseInt(stateProfile.dataUser.tierTotalBuy)
        : 0) /
        sysTierConfig[index + 1].minTotal) *
      100;

    return _percent > 100 ? 100 : _percent;
  };

  const renderMaxTotal = (index) => {
    const _point =
      parseInt(sysTierConfig[index + 1].minTotal) -
      (stateProfile.dataUser.tierTotalBuy
        ? parseInt(stateProfile.dataUser.tierTotalBuy)
        : 0);

    return _point > 0 ? _point : 0;
  };

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageTier">
        <main>
          <div className="wrapContent">
            <section id="pTier">
              <div className="bTierPoint">
                <div className="tierHead">
                  <img src={img_picTierLogo} />
                </div>
                <div className="tierPoint">
                  {tierUser < 4 ? (
                    <div className="bTier">
                      {sysTierConfig !== null &&
                        sysTierConfig.map(
                          (item, index) =>
                            item.curTier === tierUser && (
                              <div className="tierName" key={item.id}>
                                <p>{item.name}</p>
                                {sysTierConfig[index + 1] && (
                                  <p>{sysTierConfig[index + 1].name}</p>
                                )}
                              </div>
                            )
                        )}
                      {sysTierConfig !== null &&
                        sysTierConfig.map(
                          (item, index) =>
                            item.curTier === tierUser && (
                              <div
                                className={
                                  "tierBar " + (tierUser === 0 && "hide")
                                }
                                data-current={item.curTier + 1}
                                data-goal={item.nextTier && item.nextTier + 1}
                                key={item.id}
                              >
                                <div
                                  className="userPoint"
                                  style={{
                                    width: `${renderPointPercent(index)}%`,
                                  }}
                                ></div>
                              </div>
                            )
                        )}
                    </div>
                  ) : (
                    <div className="ImgPlatinum"></div>
                  )}
                  <div className={"bUpgrade " + (tierUser !== 0 && "hide")}>
                    {sysTierConfig !== null &&
                      sysTierConfig.map(
                        (item, index) =>
                          item.curTier === tierUser && (
                            <div
                              className="tierBarUpgrade"
                              data-current={item.curTier + 1}
                              data-goal={item.nextTier && item.nextTier + 1}
                              key={item.id}
                            >
                              <div className="upgradeBtn">
                                <a>อัพเกรดเป็นสมาชิก VIP</a>
                              </div>
                            </div>
                          )
                      )}
                  </div>

                  <div
                    className={
                      "benefitBanner upgrade " + (tierUser !== 0 && "hide")
                    }
                  ></div>
                  <div className={"bUserPoint " + (tierUser === 0 && "hide")}>
                    {sysTierConfig !== null &&
                      sysTierConfig.map(
                        (item, index) =>
                          item.curTier === tierUser && (
                            <div key={item.id}>
                              <div className="tTier">
                                <p>ยอดซื้อสะสมปัจจุบัน</p>
                                <div className="bPoint">
                                  <p className="tPoint">
                                    <span>
                                      {stateProfile.dataUser.tierTotalBuy
                                        ? Helper.FN.toNumberWithCommas(
                                            parseInt(
                                              stateProfile.dataUser.tierTotalBuy
                                            )
                                          )
                                        : 0}
                                    </span>
                                    {sysTierConfig[index + 1] &&
                                      `/${Helper.FN.toNumberWithCommas(
                                        sysTierConfig[index + 1].minTotal
                                      )}`}
                                  </p>
                                </div>
                              </div>
                              <p className="tDetail">
                                {sysTierConfig[index + 1] &&
                                  `สะสมเพิ่มอีก ${Helper.FN.toNumberWithCommas(
                                    renderMaxTotal(index)
                                  )}.- เพื่อเลื่อนระดับเป็น ${
                                    sysTierConfig[index + 1].name
                                  }`}
                              </p>
                            </div>
                          )
                      )}
                  </div>
                </div>
              </div>
              <div className="bTierDetail">
                <div className="bHead">
                  <p>สิทธิประโยชน์แต่ละระดับ</p>
                </div>
                <div className="tierCondition">
                  <div className="bBtnTab">
                    {sysTierConfig !== null &&
                      sysTierConfig.map((item, index) => (
                        <div
                          key={item.id}
                          className={`bLogo${index + 1} ${
                            tierUser === item.curTier ? "active" : ""
                          }`}
                          id={`btnTab${index + 1}`}
                          data-tab={index + 1}
                        >
                          <div
                            className={`logo${index + 1}`}
                            data-tab={index + 1}
                          ></div>
                          <p data-tab={index + 1}>{item.name}</p>
                        </div>
                      ))}
                  </div>
                  <div className="tDescription">
                    {sysTierConfig !== null &&
                      sysTierConfig.map((item, index) => (
                        <div
                          key={item.id}
                          className="bText"
                          id={`tab${index + 1}`}
                          dangerouslySetInnerHTML={{
                            __html:
                              item.benefitDetail &&
                              item.benefitDetail.replaceAll(
                                "className",
                                "class"
                              ),
                          }}
                          style={{
                            display:
                              tierUser !== item.curTier ? "none" : "flex",
                          }}
                        ></div>
                      ))}
                  </div>
                </div>
              </div>
            </section>
            <section id="pActionBtn">
              <div className="bBtn">
                <a className="btn outline" onClick={goBack}>
                  ย้อนกลับ
                </a>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default Tier;
