import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/th"; // Import Thai locale
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CardHealthService from "../../../../components/card/healthservice/CardHealthService";
import HeaderHealthService from "../../../../components/card/healthservice/HeaderHealthService";
import ModalDisease from "../../../../components/modal/healthservice/main/ModalDisease";
import ModalPill from "../../../../components/modal/healthservice/main/ModalPill";
import ModalSuccess from "../../../../components/modal/healthservice/ModalSuccess";
import {
  createHealthCard,
  getSearchSlot,
  HealthCardForm,
} from "../../../../services/Api/Module/HealthService";
import { branch } from "../../../../services/Api/Module/Customer";
import ModalLoading from "../../../../components/modal/healthservice/ModalLoading";
import ModalConfirm from "../../../../components/modal/healthservice/ModalConfirm";
import ModalError from "../../../../components/modal/healthservice/ModalError";
import ModalConfirm1 from "../../../../components/modal/healthservice/main/ModalConfirm1";
import helper from "../../../../services/helper";

const HealthServiceMain = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);
  /* Handle goto page */
  const navigate = useNavigate();

  const [isAppointmentNeeded, setIsAppointmentNeeded] = useState(false);
  const [dateTime, setDateTime] = useState(moment());
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [pressureTop, setPressureTop] = useState(0.0);
  const [pressureBottom, setPressureBottom] = useState(0.0);
  const [sugar, setSugar] = useState("");
  const [fat, setFat] = useState("");
  const [visceralfat, setVisceralfat] = useState("");
  const [muscle, setMuscle] = useState("");
  const [bone, setBone] = useState("");
  const [nextAppointmentBranch, setNextAppointmentBranch] = useState("");
  const [nextAppointmentDate, setNextAppointmentDate] = useState("");
  const [nextAppointmentPeriod, setNextAppointmentPeriod] = useState("");
  const [nextAppointmentPolicy, setNextAppointmentPolicy] = useState("");

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);
  const [isBackModalOpen, setIsBackModalOpen] = useState(false);
  const [isDiseaseModalOpen, setIsDiseaseModalOpen] = useState(false);
  const [isPillModalOpen, setIsPillModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isConfirm1ModalOpen, setIsConfirm1ModalOpen] = useState(false);
  const [selectedDiseases, setSelectedDiseases] = useState([]);
  const [selectedPill, setSelectedPill] = useState([]);
  const [selectedOtherDiseases, setSelectedOtherDiseases] = useState();
  const [selectedOtherPill, setSelectedOtherPill] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isModalConfirm1, setIsModalConfirm1] = useState(false);

  const [diseaseAll, setDiseaseAll] = useState([]);
  const [pillAll, setPillAll] = useState([]);
  const [branchAll, setBranchAll] = useState([]);
  const [periodAll, setPeriodAll] = useState([]);

  const [isEmptyData, setIsEmptyData] = useState(true);
  const [selectTimeLabel, setSelectTimeLabel] = useState(
    "เลือกเวลานัดตรวจสุขภาพ"
  );

  const handleRadioChange = (e) => {
    setIsAppointmentNeeded(e.target.value === "yes");
  };
  const handlePolicyCheckbox = (e) => {
    setNextAppointmentPolicy(e.target.checked ? 1 : 0);
  };
  const handleBack = () => {
    setIsEmptyData(true);
    setWeight("");
    setHeight("");
    setPressureTop("");
    setPressureBottom("");
    setSugar("");
    setFat("");
    setVisceralfat("");
    setMuscle("");
    setBone("");
    setSelectedDiseases([]);
    setSelectedOtherDiseases("");
    setSelectedPill([]);
    setSelectedOtherPill("");
    setNextAppointmentBranch("");
    setNextAppointmentDate("");
    setNextAppointmentPeriod("");
    setNextAppointmentPolicy("");
    setIsBackModalOpen(false);
  };
  const handleConfirm1 = async () => {
    setIsConfirm1ModalOpen(false);
    setIsLoadingModalOpen(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    let payload = {
      customer: stateProfile.id,
      weight: weight,
      height: height,
      sbp: pressureTop,
      dbp: pressureBottom,
      bloodSugar: sugar,
      bodyFat: fat,
      visceralFat: visceralfat,
      muscleMass: muscle,
      boneMass: bone,
      congenitalDisease: selectedDiseases,
      otherDisease: selectedOtherDiseases,
      drugAllergy: selectedPill,
      otherAllergy: selectedOtherPill,
      branch: nextAppointmentBranch,
      isConsent: nextAppointmentPolicy,
    };
    if (isAppointmentNeeded) {
      payload = {
        ...payload,
        isNextAppointment: 1,
        appointmentDate: nextAppointmentDate,
        appointmentStartTime: nextAppointmentPeriod.split(" - ")[0],
        appointmentEndTime: nextAppointmentPeriod.split(" - ")[1],
      };
    } else {
      payload.isNextAppointment = 0;
    }
    fetchPostCreateHealthCard(payload);
  };

  const handleOpenDiseaseModal = () => {
    setIsDiseaseModalOpen(true);
  };
  const handleOpenPillModal = () => {
    setIsPillModalOpen(true);
  };
  const handleOpenBackModal = () => {
    setIsBackModalOpen(true);
  };

  const handleCloseDiseaseModal = () => {
    setIsDiseaseModalOpen(false);
  };

  const handleConfirmDiseases = (diseases, other) => {
    console.log("Confirming diseases:", diseases);
    setSelectedDiseases(diseases);
    setSelectedOtherDiseases(other);
    setIsDiseaseModalOpen(false);
  };
  const handleConfirmPill = (pill, other) => {
    console.log("Confirming diseases:", pill);
    setSelectedPill(pill);
    setSelectedOtherPill(other);
    setIsPillModalOpen(false);
  };
  const handleConfirmSuccess = () => {
    setIsSuccessModalOpen(false);
    navigate("/healthservice-catagory");
  };

  const fetchGetHealthCardForm = async (payload) => {
    try {
      const response = await HealthCardForm({ params: payload });
      const resData = response?.data;
      if (resData.resultCode === 20200) {
        if (Object.keys(resData.body.healthInfo).length === 0) {
          setIsEmptyData(true);
        } else {
          navigate("/healthservice-catagory");
          setIsEmptyData(false);
        }
        const diseaseIds = resData.body.congenitalDiseaseMap.map(
          (disease) => disease.id
        );
        const pillIds = resData.body.drugAllergyMap.map((pill) => pill.id);
        setWeight(resData.body.healthInfo?.weight || "");
        setHeight(resData.body.healthInfo?.height || "");
        setPressureTop(resData.body.healthInfo?.sbp || "");
        setPressureBottom(resData.body.healthInfo?.dbp || "");
        setSugar(resData.body.healthInfo?.bloodSugar || "");
        setVisceralfat(resData.body.healthInfo?.visceralFat || "");
        setFat(resData.body.healthInfo?.bodyFat || "");
        setBone(resData.body.healthInfo?.boneMass || "");
        setMuscle(resData.body.healthInfo?.muscleMass || "");
        setSelectedDiseases(diseaseIds || []);
        setSelectedPill(pillIds || []);
        setSelectedOtherDiseases(resData.body.healthInfo?.otherDisease || "");
        setSelectedOtherPill(resData.body.healthInfo?.otherAllergy || "");
        setDiseaseAll(resData.body.congenitalDiseaseOption || "");
        setPillAll(resData.body.drugAllergyOption || "");
      } else {
        setIsErrorModalOpen(true);
      }
    } catch (err) {
      console.log("err ", err);
    }
  };
  const fetchPostCreateHealthCard = async (payload) => {
    try {
      const response = await createHealthCard({ data: payload });
      const resData = response?.data;
      if (resData.resultCode === 20200) {
        setIsLoadingModalOpen(false);
        setIsSuccessModalOpen(true);
      } else {
        setIsLoadingModalOpen(false);
        setIsErrorModalOpen(true);
      }
    } catch (err) {
      console.log("err ", err);
    }
  };
  const fetchGetSearchSlot = async (payload) => {
    try {
      const response = await getSearchSlot({ params: payload });
      const resData = response?.data;
      setPeriodAll(resData.body);
      if (resData.body.length == 0) {
        setSelectTimeLabel("ไม่พบข้อมูล");
      } else {
        setSelectTimeLabel("เลือกเวลานัดตรวจสุขภาพ");
      }
    } catch (err) {
      console.log("err ", err);
    }
  };
  const fetchGetBranch = async (payload) => {
    try {
      const response = await branch({});
      const resData = response?.data;
      setBranchAll(resData.body);
    } catch (err) {
      console.log("err ", err);
    }
  };

  useEffect(() => {
    moment.locale("th"); // Set the locale to Thai
    const timer = setInterval(() => setDateTime(moment()), 1000);
    return () => clearInterval(timer);
  }, []);
  useEffect(() => {
    if (stateProfile.id === 0) {
      navigate("/");
    }
    let payload = {
      customerId: stateProfile.id,
    };
    fetchGetHealthCardForm(payload);
    fetchGetBranch();
  }, []);
  useEffect(() => {
    if (nextAppointmentBranch && nextAppointmentDate) {
      let payload = {
        branch: nextAppointmentBranch,
        day: moment(nextAppointmentDate).locale("en").format("dddd"),
      };
      fetchGetSearchSlot(payload);
    }
  }, [nextAppointmentBranch, nextAppointmentDate]);

  const isFormValid = () => {
    const isPressureValid =
      (pressureTop !== "" && pressureBottom !== "") ||
      (pressureTop === "" && pressureBottom === "");

    const baseValidation =
      height.trim() !== "" &&
      weight.trim() !== "" &&
      nextAppointmentPolicy == 1 &&
      isPressureValid;

    if (isAppointmentNeeded) {
      return (
        baseValidation &&
        nextAppointmentDate.trim() !== "" &&
        nextAppointmentBranch.trim() !== "" &&
        nextAppointmentPeriod.trim() !== ""
      );
    } else {
      return baseValidation;
    }
  };

  useEffect(() => {
    // console.log(isFilterSubmit);
  }, [isSubmit]);

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageHealthServiceMain">
        <HeaderHealthService />
        <main>
          <div className="wrapContent">
            <section id="pHealthServiceMain">
              <div className={isEmptyData ? "formGroup noData" : "formGroup"}>
                <CardHealthService />
                <div className="bHealthDetail">
                  <div className="bForm">
                    <p className="tHead">กรอกข้อมูลสุขภาพ</p>
                    <div className="bTime">
                      <p className="tBold">วันที่บันทึกข้อมูล :</p>
                      <p>{dateTime.format("DD / MM / YYYY")}</p>
                      <p className="tBold">เวลาที่บันทึกข้อมูล :</p>
                      <p>{dateTime.format("HH.mm")} น.</p>
                    </div>
                    <div className="control-group validate">
                      <div className="tTitle">ส่วนสูง</div>
                      <input
                        type="text"
                        name="height"
                        value={height}
                        onChange={(e) => {
                          setHeight(e.target.value);
                        }}
                        inputMode="numeric"
                        placeholder="กรอกส่วนสูง"
                      />
                      <label htmlFor="" className="tError">
                        Text Error Input
                      </label>
                    </div>
                    <div className="control-group validate">
                      <div className="tTitle">น้ำหนัก</div>
                      <input
                        type="text"
                        name="weight"
                        value={weight}
                        onChange={(e) => {
                          setWeight(e.target.value);
                        }}
                        inputMode="numeric"
                        placeholder="กรอกน้ำหนัก"
                      />
                      <label htmlFor="" className="tError">
                        Text Error Input
                      </label>
                    </div>
                    <div className="bInline">
                      <p className="sym1">ระดับความดัน</p>
                      <div className="bSelectNumber">
                        <div className="control-group-selectNumber">
                          <input
                            type="text"
                            name="pressureTop"
                            value={pressureTop}
                            onChange={(e) => {
                              setPressureTop(e.target.value);
                            }}
                            inputMode="decimal"
                            placeholder="กรอกตัวบน"
                          />
                          <div className="btnControl">
                            <div
                              className="up"
                              onClick={() =>
                                setPressureTop((prev) => {
                                  const currentValue = parseFloat(prev) || 0;
                                  return (currentValue + 1).toFixed(2);
                                })
                              }
                            ></div>
                            <div
                              className="down"
                              onClick={() =>
                                setPressureTop((prev) => {
                                  const currentValue = parseFloat(prev) || 0;
                                  return (currentValue - 1).toFixed(2);
                                })
                              }
                            ></div>
                          </div>
                        </div>
                        <div className="control-group-selectNumber">
                          <input
                            type="text"
                            name="pressureBottom"
                            value={pressureBottom}
                            onChange={(e) => {
                              setPressureBottom(e.target.value);
                            }}
                            inputMode="decimal"
                            placeholder="กรอกตัวล่าง"
                          />
                          <div className="btnControl">
                            <div
                              className="up"
                              onClick={() =>
                                setPressureBottom((prev) => {
                                  const currentValue = parseFloat(prev) || 0;
                                  return (currentValue + 1).toFixed(2);
                                })
                              }
                            ></div>
                            <div
                              className="down"
                              onClick={() =>
                                setPressureBottom((prev) => {
                                  const currentValue = parseFloat(prev) || 0;
                                  return (currentValue - 1).toFixed(2);
                                })
                              }
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bInput">
                      <p className="sym2">ระดับน้ำตาล</p>
                      <div className="control-group-selectNumber">
                        <input
                          type="text"
                          name="sugar"
                          value={sugar}
                          onChange={(e) => setSugar(e.target.value)}
                          inputMode="decimal"
                          placeholder="กรอกระดับน้ำตาล"
                        />
                        <div className="btnControl">
                          <div
                            className="up"
                            onClick={() =>
                              setSugar((prev) => {
                                const currentValue = parseFloat(prev) || 0;
                                return (currentValue + 1).toFixed(2);
                              })
                            }
                          ></div>
                          <div
                            className="down"
                            onClick={() =>
                              setSugar((prev) => {
                                const currentValue = parseFloat(prev) || 0;
                                return Math.max(0, currentValue - 1).toFixed(2);
                              })
                            }
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="bInput">
                      <p className="sym3">%ไขมันในร่างกาย</p>
                      <div className="control-group-selectNumber">
                        <input
                          type="text"
                          name="fat"
                          value={fat}
                          onChange={(e) => {
                            setFat(e.target.value);
                          }}
                          inputMode="decimal"
                          placeholder="กรอกเปอร์เซ็นต์ไขมันในร่างกาย"
                        />
                        <div className="btnControl">
                          <div
                            className="up"
                            onClick={() =>
                              setFat((prev) => {
                                const currentValue = parseFloat(prev) || 0;
                                return Math.max(0, currentValue + 1).toFixed(2);
                              })
                            }
                          ></div>
                          <div
                            className="down"
                            onClick={() =>
                              setFat((prev) => {
                                const currentValue = parseFloat(prev) || 0;
                                return Math.max(0, currentValue - 1).toFixed(2);
                              })
                            }
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="bInput">
                      <p className="sym4">ไขมันในช่องท้อง</p>
                      <div className="control-group-selectNumber">
                        <input
                          type="text"
                          name="visceralfat"
                          value={visceralfat}
                          onChange={(e) => setVisceralfat(e.target.value)}
                          inputMode="decimal"
                          placeholder="กรอกไขมันในช่องท้อง"
                        />
                        <div className="btnControl">
                          <div
                            className="up"
                            onClick={() =>
                              setVisceralfat((prev) => {
                                const currentValue = parseFloat(prev) || 0;
                                return Math.max(0, currentValue + 1).toFixed(2);
                              })
                            }
                          ></div>
                          <div
                            className="down"
                            onClick={() =>
                              setVisceralfat((prev) => {
                                const currentValue = parseFloat(prev) || 0;
                                return Math.max(0, currentValue - 1).toFixed(2);
                              })
                            }
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="bInput">
                      <p className="sym5">มวลกล้ามเนื้อ</p>
                      <div className="control-group-selectNumber">
                        <input
                          type="text"
                          name="muscle"
                          value={muscle}
                          onChange={(e) => setMuscle(e.target.value)}
                          inputMode="decimal"
                          placeholder="กรอกมวลกล้ามเนื้อ"
                        />
                        <div className="btnControl">
                          <div
                            className="up"
                            onClick={() =>
                              setMuscle((prev) => {
                                const currentValue = parseFloat(prev) || 0;
                                return Math.max(0, currentValue + 1).toFixed(2);
                              })
                            }
                          ></div>
                          <div
                            className="down"
                            onClick={() =>
                              setMuscle((prev) => {
                                const currentValue = parseFloat(prev) || 0;
                                return Math.max(0, currentValue - 1).toFixed(2);
                              })
                            }
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="bInput">
                      <p className="sym6">มวลกระดูก</p>
                      <div className="control-group-selectNumber">
                        <input
                          type="text"
                          name="bone"
                          value={bone}
                          onChange={(e) => setBone(e.target.value)}
                          inputMode="decimal"
                          placeholder="กรอกมวลกระดูก"
                        />
                        <div className="btnControl">
                          <div
                            className="up"
                            onClick={() =>
                              setBone((prev) => {
                                const currentValue = parseFloat(prev) || 0;
                                return Math.max(0, currentValue + 1).toFixed(2);
                              })
                            }
                          ></div>
                          <div
                            className="down"
                            onClick={() =>
                              setBone((prev) => {
                                const currentValue = parseFloat(prev) || 0;
                                return Math.max(0, currentValue - 1).toFixed(2);
                              })
                            }
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="bInput">
                      <p className="">โรคประจำตัว</p>
                      <div
                        className="InputPopupDetail"
                        onClick={handleOpenDiseaseModal}
                      >
                        {selectedDiseases.length > 0 ||
                        selectedOtherDiseases ? (
                          <>
                            {diseaseAll
                              .filter((item) =>
                                selectedDiseases.includes(item.id)
                              )
                              .map((item) => (
                                <div key={item.id} className="choiceSelect">
                                  {item.name}
                                </div>
                              ))}
                            {selectedOtherDiseases && (
                              <div className="choiceSelect">
                                {selectedOtherDiseases}
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="choiceSelect">เลือกโรคประจำตัว</div>
                        )}
                      </div>
                    </div>
                    <div className="bInput">
                      <p className="">ประวัติแพ้ยา</p>
                      <div
                        className="InputPopupDetail"
                        onClick={handleOpenPillModal}
                      >
                        {selectedPill.length > 0 || selectedOtherPill ? (
                          <>
                            {pillAll
                              .filter((item) => selectedPill.includes(item.id))
                              .map((item) => (
                                <div key={item.id} className="choiceSelect">
                                  {item.name}
                                </div>
                              ))}
                            {selectedOtherPill && (
                              <div className="choiceSelect">
                                {selectedOtherPill}
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="choiceSelect">
                            เลือกรายชื่อยาที่มีอาการแพ้
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="bInput ">
                      <p>ต้องการนัดหมายตรวจสุขภาพครั้งต่อไปไหม?</p>
                      <div className="control-group">
                        <label className="control control--radio">
                          ต้องการ
                          <input
                            type="radio"
                            name="radio"
                            value="yes"
                            onChange={handleRadioChange}
                            checked={isAppointmentNeeded}
                          />
                          <div className="control__indicator"></div>
                        </label>
                        <label className="control control--radio">
                          ไม่ต้องการ
                          <input
                            type="radio"
                            name="radio"
                            value="no"
                            onChange={handleRadioChange}
                            checked={!isAppointmentNeeded}
                          />
                          <div className="control__indicator"></div>
                        </label>
                      </div>
                    </div>
                  </div>
                  {isAppointmentNeeded && (
                    <div className="bNextMeeting bForm">
                      <p className="tHead">นัดหมายตรวจสุขภาพครั้งต่อไป</p>
                      <div className="bInput control-group validate">
                        <div className="tTitle">เลือกสาขา</div>
                        <div className="select">
                          <select
                            value={nextAppointmentBranch}
                            onChange={(e) => {
                              setNextAppointmentBranch(e.target.value);
                              setNextAppointmentDate("");
                              setNextAppointmentPeriod("");
                            }}
                          >
                            <option value="">เลือกสาขานัดตรวจสุขภาพ</option>
                            {branchAll.map((item, index) => (
                              <option key={index} value={item.id}>
                                {item.nameFe}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="bInput control-group validate">
                        <div className="tTitle">เลือกวันที่นัดตรวจสุขภาพ</div>
                        <input
                          type="date"
                          name="appointmentDate"
                          value={nextAppointmentDate}
                          onChange={(e) => {
                            setNextAppointmentDate(e.target.value);
                            setNextAppointmentPeriod("");
                          }}
                          min={moment().format("YYYY-MM-DD")} // Set minimum date to today
                          required
                        />
                      </div>
                      <div className="bInput control-group validate">
                        <div className="tTitle">
                          เลือกเวลา (ขึ้นกับเวลาเปิดปิดของสถานที่ให้บริการ)
                        </div>
                        <div className="select">
                          <select
                            value={nextAppointmentPeriod}
                            onChange={(e) =>
                              setNextAppointmentPeriod(e.target.value)
                            }
                          >
                            <option value="">{selectTimeLabel}</option>
                            {periodAll.map((item, index) => (
                              <option key={index} value={item.appointmentTime}>
                                {item.appointmentTime}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="bNextMeeting">
                    <label className="control control--checkbox">
                      ข้าพเจ้าได้อ่าน{" "}
                      <a
                        onClick={() => {
                          navigate("/privacy-policy");
                        }}
                      >
                        นโยบายความเป็นส่วนตัว
                      </a>
                      {/* <br /> */}
                      และยินยอมรับเงื่อนไขของการเป็นสมาชิก
                      <input
                        type="checkbox"
                        checked={nextAppointmentPolicy === 1}
                        onChange={handlePolicyCheckbox}
                      />
                      <div className="control__indicator"></div>
                    </label>
                  </div>
                </div>
                <div class="noDataIcon">
                  <img
                    src="./assets/img/ci/ico-healthservice/ico-noData.svg"
                    alt=""
                  />
                  <p>คุณยังไม่มีบันทึกสุขภาพ</p>
                </div>
              </div>
            </section>

            <section id="pActionBtn">
              <div className="bBtn">
                {isEmptyData ? (
                  <button className="btn" onClick={() => setIsEmptyData(false)}>
                    เริ่มต้นการใช้งาน
                  </button>
                ) : (
                  <>
                    <button
                      className="btn outline"
                      onClick={handleOpenBackModal}
                    >
                      ย้อนกลับ
                    </button>
                    <button
                      className="btn"
                      onClick={() => setIsConfirm1ModalOpen(true)}
                      disabled={!isFormValid()}
                    >
                      ยืนยันข้อมูล
                    </button>
                  </>
                )}
              </div>
            </section>
          </div>
        </main>
      </div>
      <ModalDisease
        open={isDiseaseModalOpen}
        onClose={handleCloseDiseaseModal}
        onConfirm={handleConfirmDiseases}
        diseases={diseaseAll}
        selected={selectedDiseases}
        other={selectedOtherDiseases}
      />
      <ModalPill
        open={isPillModalOpen}
        onClose={() => setIsPillModalOpen(false)}
        onConfirm={handleConfirmPill}
        pill={pillAll}
        selected={selectedPill}
        other={selectedOtherPill}
      />
      <ModalSuccess
        open={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
        onConfirm={handleConfirmSuccess}
        Header="สร้างบันทึกข้อมูลสุขภาพแล้ว!"
      />
      <ModalLoading open={isLoadingModalOpen} />
      <ModalConfirm
        open={isBackModalOpen}
        onClose={() => setIsBackModalOpen(false)}
        onConfirm={handleBack}
        header="ยืนยันการย้อนกลับ"
        body1="การย้อนกลับจะทำให้"
        body2="ข้อมูลที่คุณกรอกไว้สูญหาย"
        body3="คุณต้องการจะย้อนกลับหรือไม่?"
        button1="ย้อนกลับ"
      />
      <ModalError
        open={isErrorModalOpen}
        onClose={() => setIsErrorModalOpen(false)}
        onConfirm={() => setIsErrorModalOpen(false)}
      />
      <ModalConfirm1
        open={isConfirm1ModalOpen}
        onClose={() => setIsConfirm1ModalOpen(false)}
        onConfirm={handleConfirm1}
        header="ยืนยันการบันทึกข้อมูล"
        body1="เมื่อยืนยันแล้ว"
        body2="ท่านจะไม่สามารถแก้ไขข้อมูลสุขภาพ"
        body4="และนัดหมายตรวจสุขภาพได้"
        body3="เว้นแต่จะทำการบันทึกทั้งหมดใหม่"
        button1="ยืนยันข้อมูล"
      />
    </>
  );
};

export default HealthServiceMain;
