import { Dialog } from "primereact/dialog";

const ModalError = ({
  open = false,
  onClose = () => {},
  onConfirm = () => {}
}) => {

  return (
    <Dialog
      visible={open}
      style={{ width: "325px" }}
      onHide={() => onClose()}
      draggable={false}
      resizable={false}
      closable={false}
    >
      <div className="bModal bCard" id="modalUpdateSuccess">
        <h2>เกิดข้อผิดพลาด</h2>
        <p>
          {" "}
          กรุณาลองใหม่อีกครั้ง
        </p>

        <button className="btn btnCloseModal" onClick={onConfirm}>
          ตกลง
        </button>
      </div>
    </Dialog>
  );
};

export default ModalError;
