import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetTransaction } from "../../../../../features/TransactionSlice";

import moment from "moment";

const ReturnTransactionHistoryDetail = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateTransaction = useSelector((state) => state.transaction);

  const resetDataTransaction = () => {
    dispatch(resetTransaction()); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goback */
  const navigate = useNavigate();

  const handleGoBack = () => {
    resetDataTransaction();

    navigate(-1);
  };
  /* End Handle goback */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !!", stateTransaction);

    if (
      stateTransaction.id === 0 &&
      Object.getOwnPropertyNames(stateTransaction.data).length === 0
    ) {
      navigate("/profile");
    }
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageReturnHistory-details">
        <main>
          <div className="wrapContent">
            <section id="pReturnHistoryDetailsMain">
              <div className="bReturnHistoryDetails return">
                <div className="bHead">
                  <div className="bGroup return">
                    <div className="bRow return">
                      <h1>เลขที่คืนสินค้า</h1>
                      <h1>{stateTransaction.data.returnNo}</h1>
                    </div>
                  </div>
                </div>
                <div className="bDetails">
                  <div className="bGroup">
                    <div className="bRow">
                      <p>หมายเลขคำสั่งซื้อ :</p>
                      <p>
                        {stateTransaction.data.buyNo &&
                          stateTransaction.data.buyNo}
                      </p>
                    </div>
                    <div className="bRow">
                      <p>วันและเวลาที่คืนสินค้า :</p>
                      <p className="textEnd">
                        {stateTransaction.data.returnDate &&
                          moment(stateTransaction.data.returnDate).format(
                            "DD/MM/"
                          ) +
                            (parseInt(
                              moment(stateTransaction.data.returnDate).format(
                                "YYYY"
                              )
                            ) +
                              543) +
                            " " +
                            moment(stateTransaction.data.returnDate).format(
                              "HH:mm"
                            ) +
                            ` น.`}
                      </p>
                    </div>
                    <div className="bRow text">
                      <p>สาขา :</p>
                      <p className="right textEnd">
                        {stateTransaction.data.branch}
                      </p>
                    </div>
                  </div>
                  <div className="itemList-return">
                    <div className="tHead">
                      <p>จำนวน</p>
                      <p>ชื่อสินค้า</p>
                      <p>ราคา</p>
                    </div>
                    {stateTransaction.data.products &&
                      stateTransaction.data.products.map(
                        ({ name, price, amount }, index) => (
                          <div className="itemList" key={index + 1}>
                            <p>{amount && parseInt(amount).toLocaleString()}</p>
                            <p className="tItem">{name}</p>
                            <p className="total">
                              {price && parseInt(price).toLocaleString()}
                            </p>
                          </div>
                        )
                      )}
                  </div>
                  <div className="bGroup total">
                    <div className="bRow">
                      <p>ราคารวมสุทธิ</p>
                      <p className="total bold textEnd">
                        {stateTransaction.data.totalPrice &&
                          parseInt(
                            stateTransaction.data.totalPrice
                          ).toLocaleString()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="pActionBtn">
              <div className="bBtn">
                <button className="btn outline" onClick={handleGoBack}>
                  ย้อนกลับ
                </button>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default ReturnTransactionHistoryDetail;
