import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import $ from "jquery";
import jBox from "jbox";

const ModalConfirmRedeem = ({
  open = false,
  onClose = () => {},
  submitCallback = () => {},
}) => {
  const [modalConfirmRedeem, setModalConfirmRedeem] = useState(null);
  const [clickClose, setClickClose] = useState(false); //block twic click close
  const [clickSubmit, setClickSubmit] = useState(false); //block twic click submit

  const openModal = () => {
    modalConfirmRedeem.open();
  };

  const closeModal = () => {
    modalConfirmRedeem.close();
    onClose();
  };

  const listenerCloseModal = () => {
    if (!clickClose) {
      const btnCancel = document.getElementById("btnCancelRedeem");
      btnCancel.addEventListener("click", () => {
        closeModal();
        setClickClose(true);
      });
    }
  };

  const listenerConfirm = () => {
    if (!clickSubmit) {
      const btnConfirm = document.getElementById("btnConfirmRedeem");
      btnConfirm.addEventListener("click", () => {
        submitCallback(true);
        closeModal();
        setClickSubmit(true);
      });
    }
  };

  useEffect(() => {}, [clickClose, clickSubmit]);

  /* First event render */
  useEffect(() => {
    const initModal = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 375,
      content: $("#ModalConfirm"),
    });
    setModalConfirmRedeem(initModal);

    return () => {
      initModal.destroy();
    };
  }, []);
  /* End First event render */

  useEffect(() => {
    // console.log("modal useEffect2!! >>", modalConfirmRedeem, open);

    if (modalConfirmRedeem && open) {
      openModal();

      listenerCloseModal();
      listenerConfirm();
    }
  }, [modalConfirmRedeem, open]);

  return (
    <div className="bModal bCard" id="ModalConfirm">
      <div className="bInner">
        <div className="bTitle">
          <span>ยืนยันการใช้คูปอง</span>
        </div>
        <div className="bBtn">
          <button className="btn" id="btnConfirmRedeem">
            ยืนยัน
          </button>
          <button className="btn outline btnCloseModal" id="btnCancelRedeem">
            ย้อนกลับ
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirmRedeem;
