import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRegister } from "../../../features/RegisterSlice";

import img_icoSuccess from "../../../assets/img/ci/icon-register/ico-success.svg";
import img_icoBannerGreen from "../../../assets/img/ci/icon-banner/ico-banner-green.png";

// import useTranslations from "../../../i18n/useTranslations";

const Welcome = () => {
  // const { t } = useTranslations(); // แปลภาษา

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);

  const setDataRegis = (newData = {}) => {
    const tmpObj = { dataUser: newData };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Navigate */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("You clicked", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Navigate */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !", stateProfile);

    if (stateProfile.id === 0) {
      navigate("/");
    }

    setDataRegis({});
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageRegisterSuccess">
        <main>
          <div className="wrapContent">
            <section id="pWelcomeMain">
              <div className="bText">
                <img src={img_icoSuccess} alt=""/>
                <h1>สมัครสมาชิกสำเร็จ</h1>
                <img src={img_icoBannerGreen} alt=""/>
                <p>ขอบคุณที่เป็นส่วนหนึ่งกับ Health up</p>
              </div>
              <div className="bBtn">
                <button
                  className="btn"
                  onClick={handleGotoPage}
                  data-page="profile"
                >
                  เข้าสู่หน้าโปรไฟล์
                </button>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default Welcome;
