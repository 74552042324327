import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTransition, animated } from "react-spring";

import { custCoupon } from "../../../../services/Api/Module/Customer";

import PageTitleMember from "../../../../layouts/inc/PageTitleMember";
import CardCoupon from "../../../../components/card/promotion/coupon/CardCoupon";
import MenuMember from "../../../../components/menu/MenuMember";

import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import img_icoTicketHide from "../../../../assets/img/ci/ico-coupon/ico-ticket-hide.svg";

const MyCoupon = ({ articleItem }) => {
  let height = 0;

  const transitions = useTransition(
    articleItem.map((data) => ({
      ...data,
      y:
        (height += data.props.curHeight ? data.props.curHeight + 16 : 146) -
        (data.props.curHeight ? data.props.curHeight + 16 : 146),
    })),
    {
      key: (item) => item.key,
      from: { height: 0, opacity: 0, transform: "translateY(30px)" },
      enter: ({ y, height }) => ({
        y,
        height,
        opacity: 1,
        transform: "translateY(0)",
      }),
      leave: { height: 0, opacity: 0, transform: "translateY(30px)" },
      update: ({ data, y, height }) => ({ data, y, height }),
    }
  );

  return (
    <div
      className="item-group"
      style={{ position: "relative", height: height }}
    >
      {transitions((style, item, t, index) => (
        <animated.div
          className="item-coupon"
          style={{
            ...style,
            zIndex: articleItem.length - index,
            willChange: "transform, height, opacity",
          }}
        >
          {item}
        </animated.div>
      ))}
    </div>
  );
};

const Coupon = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* Handle search */
  const [searchValue, setSearchValue] = useState("");

  const handleOnSearchChange = (e) => {
    // console.log("handleOnSearchChange >>", e.target.value);

    setSearchValue(e.target.value);
  };
  /* End Handle search */

  /* Handle type */
  const [typeValue, setTypeValue] = useState(null);

  const handleOnTypeChange = (e) => {
    // console.log("handleOnTypeChange >>", e.target.value);

    setTypeValue(e.target.value);
  };
  /* End Handle type */

  /* Handle sort */
  const [sortValue, setSortValue] = useState(null);

  const handleOnSortChange = (e) => {
    // console.log("handleOnSortChange >>", e.target.value);

    setSortValue(e.target.value);
  };
  /* End Handle sort */

  /* First event render */
  const [isLoading, setIsLoading] = useState(true);

  const [initItem, setInitItem] = useState([]);
  const [initHeight, setInitHeight] = useState([]);
  const [curHeight, setCurHeight] = useState([]);

  const [couponItem, setCouponItem] = useState([]);
  const [articleItem, setArticleItem] = useState([]);

  const getCustCoupon = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await custCoupon({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getCustCoupon resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getCustCoupon >> ", e);
    }

    return _return;
  };

  useEffect(() => {
    // console.log("useEffect !", stateProfile);

    if (stateProfile.id === 0) {
      navigate("/");
    } else {
      if (
        Object.getOwnPropertyNames(stateProfile.dataUser).length === 0 ||
        stateProfile.isLoadProfile === false
      ) {
        navigate("/profile");
      } else {
        getCustCoupon({
          custId: stateProfile.id,
        }).then((_rsDataCoupon) => {
          // console.log("_rsDataCoupon >>", _rsDataCoupon);

          if (
            _rsDataCoupon.status === true &&
            _rsDataCoupon.result.length !== 0
          ) {
            const _length = _rsDataCoupon.result.length;
            const _coupon = _rsDataCoupon.result;
            // console.log(_coupon);

            for (let i = 0; i < _length; i++) {
              setInitItem((prevInitItem) => [...prevInitItem, _coupon[i]]);

              setInitHeight((prevCurHeight) => [...prevCurHeight, 0]);
            }
          }

          setIsLoading(false);
        });
      }
    }
  }, []);
  /* End First event render */

  /* Set new height on resize */
  let _tmpHeight = [];

  const setNewHeight = (index, height) => {
    // console.log(index, height, _tmpHeight);
    if (height !== 0) {
      _tmpHeight[index] = height;
      setCurHeight(_tmpHeight);
    }
  };
  /* End Set new height on resize */

  useEffect(() => {
    // console.log(initItem.length, initItem);

    if (initItem.length > 0) {
      for (let i = 0; i < initItem.length; i++) {
        setCouponItem((prevCouponItem) => [
          ...prevCouponItem,
          <CardCoupon
            key={i + 1}
            index={i}
            setNewHeight={setNewHeight}
            curHeight={initHeight[i]}
            dataCoupon={initItem[i]}
          />,
        ]);

        _tmpHeight[i] = initHeight[i];
      }
    }
  }, [initItem]);

  useEffect(() => {
    if (couponItem) {
      // console.log(couponItem.length, couponItem);

      let _tmpItem = couponItem;

      if (sortValue !== null) {
        // console.log(sortValue);

        switch (sortValue) {
          case "enddate":
            _tmpItem = [...couponItem].sort((a, b) => {
              return a.props.dataCoupon.endDate > b.props.dataCoupon.endDate ||
                b.props.dataCoupon.id > a.props.dataCoupon.id
                ? 1
                : -1;
            });
            break;

          default:
            _tmpItem = [...couponItem].sort((a, b) => {
              return a.props.dataCoupon.startDate >
                b.props.dataCoupon.startDate ||
                a.props.dataCoupon.id > b.props.dataCoupon.id
                ? 1
                : -1;
            });
            break;
        }
      }

      if (typeValue !== null) {
        _tmpItem = _tmpItem.filter((item) => {
          if (item.props.dataCoupon.type === typeValue) {
            return item;
          }
        });
      }

      if (searchValue !== "") {
        // console.log(searchValue);

        _tmpItem = _tmpItem.filter((item) => {
          if (item.props.dataCoupon.name.toLowerCase().includes(searchValue)) {
            return item;
          }
        });
      }

      setArticleItem(_tmpItem);
    }
  }, [couponItem, sortValue, typeValue, searchValue]);

  useEffect(() => {
    // console.log(curHeight.length, curHeight);

    if (curHeight.length > 0) {
      curHeight.map((val, idx) => {
        setCouponItem((prevCouponItem) =>
          prevCouponItem.with(
            idx,
            <CardCoupon
              key={couponItem[idx].key}
              index={idx}
              setNewHeight={setNewHeight}
              curHeight={val}
              dataCoupon={couponItem[idx].props.dataCoupon}
            />
          )
        );
      });
    }
  }, [curHeight]);

  useEffect(() => {
    // console.log(articleItem.length, articleItem);
  }, [isLoading, articleItem]);

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageCoupon">
        <main>
          <div className="wrapContent">
            <PageTitleMember title="โปรโมชั่น" />

            <section id="pSubmenuMain">
              <div className="selectMenu">
                <div
                  className="selectHeader active"
                  onClick={handleGotoPage}
                  data-page="coupon"
                >
                  <div className="coupon">
                    <p>แลกคูปอง</p>
                    <div className="underline"></div>
                  </div>
                </div>
                <div
                  className="selectHeader"
                  onClick={handleGotoPage}
                  data-page="promotion"
                >
                  <div className="promotion">
                    <p>โปรโมชั่น</p>
                    <div className="underline"></div>
                  </div>
                </div>
              </div>
              <div className="bgList">
                <div className="bSubmenu">
                  <a
                    className="active"
                    href="#"
                    onClick={handleGotoPage}
                    data-page="coupon"
                  >
                    รายชื่อคูปอง
                  </a>
                  <a
                    className=""
                    href="#"
                    onClick={handleGotoPage}
                    data-page="coupon/history"
                  >
                    ประวัติการใช้งาน
                  </a>
                </div>
                <section id="pSearchMain">
                  <div className="bSearch">
                    <input
                      type="text"
                      name="search"
                      id="inputSearch"
                      placeholder="ค้นหา"
                      onChange={handleOnSearchChange}
                    />
                  </div>
                  <select
                    className="bSelect"
                    name="selectType"
                    defaultValue={""}
                    onChange={handleOnTypeChange}
                  >
                    <option value={""} disabled>
                      ประเภท
                    </option>
                    <option value={"02"}>แทนเงินสด</option>
                    <option value={"01"}>ส่วนลด</option>
                    <option value={"03"}>แลกซื้อสินค้า</option>
                    <option value={"04"}>อเนกประสงค์​</option>
                  </select>
                  <div className="bFilter">
                    <div className="select">
                      <select
                        name="selectSort"
                        defaultValue={""}
                        onChange={handleOnSortChange}
                      >
                        <option value={""} disabled></option>
                        <option value={"all"}>ทั้งหมด</option>
                        <option value={"enddate"}>วันหมดอายุ</option>
                      </select>
                    </div>
                  </div>
                </section>
                <section id="pListCouponMain">
                  <div className="bListCoupon">
                    {isLoading ? (
                      <div className="bNoData">
                        <p>
                          <FontAwesomeIcon icon={faSpinner} spin /> Loading...
                        </p>
                      </div>
                    ) : (
                      <>
                        {articleItem.length === 0 ? (
                          <div className="bNoData">
                            <img
                              className="grayScale"
                              src={img_icoTicketHide}
                            />
                            <p>ยังไม่มีคูปองในขณะนี้</p>
                          </div>
                        ) : (
                          <div className="bGroup">
                            <MyCoupon articleItem={articleItem} />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </section>
              </div>
            </section>

            <MenuMember pageActive="promotion" />
          </div>
        </main>
      </div>
    </>
  );
};

export default Coupon;
