import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCoupon } from "../../../../features/CouponSlice";

import img_icoTicket from "../../../../assets/img/ci/ico-coupon/ico-ticket.svg";

const CardCouponHistory = (props) => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง

  const setDataCoupon = (newData) => {
    const tmpObj = { id: newData.id, data: newData, redeem: {} };
    dispatch(setCoupon(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goto coupon detail page */
  const navigate = useNavigate();

  const handleGotoCouponHistoryDetail = (e) => {
    e.preventDefault();
    // console.log("handleGotoCouponDetail >>", props.dataCoupon);

    setDataCoupon(props.dataCoupon);

    navigate("/coupon/history/detail");
  };
  /* End Handle goto coupon detail page */

  // useEffect(() => {
  //   // console.log("props", props);
  // }, []);

  /* Check image exists */
  const [imageExists, setImageExists] = useState(false);

  useEffect(() => {
    // console.log("props", props);

    const img = new Image();
    img.src = props.dataCoupon.thumbnailImg;

    img.onload = () => {
      setImageExists(true);
    };

    // img.onerror = () => {
    //   setImageExists(false);
    // };
  }, [props.dataCoupon.thumbnailImg]);
  /* End Check image exists */

  const [height, setHeight] = useState(0);
  const eleRef = useRef(null);

  useEffect(() => {
    // console.log(props.index, height, props.curHeight);
    props.setNewHeight(props.index, height);
  }, [height]);

  useLayoutEffect(() => {
    const handleResize = () => {
      setHeight(eleRef.current.offsetHeight);
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <article
      ref={eleRef}
      data-id={props.dataCoupon.redeemId}
      data-type={props.dataCoupon.type}
      data-createdon={props.dataCoupon.redeemCreatedOn}
      data-expiredat={props.dataCoupon.redeemExpiredAt}
    >
      <div className="bPic">
        {imageExists ? (
          <img src={props.dataCoupon.thumbnailImg} />
        ) : (
          <img src={img_icoTicket} />
        )}
      </div>
      <div className="bDetails">
        <div className="bValue">
          <p>{props.dataCoupon.name}</p>
          <div
            className={`tUse ${
              props.dataCoupon.redeemIsUsed === 0 &&
              new Date().toISOString() > props.dataCoupon.redeemExpiredAt
                ? "tExp"
                : ""
            }`}
          >
            {props.dataCoupon.redeemIsUsed === 1
              ? "ใช้แล้ว"
              : props.dataCoupon.redeemIsUsed === 0 &&
                new Date().toISOString() > props.dataCoupon.redeemExpiredAt
              ? "หมดอายุ"
              : "-"}
          </div>
        </div>
        <div className="bPoint">
          <div className="bBtn">
            <a href="#" onClick={handleGotoCouponHistoryDetail}>
              ดูรายละเอียด
            </a>
          </div>
        </div>
      </div>
    </article>
  );
};

export default CardCouponHistory;
