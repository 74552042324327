import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setRegister } from "../../../features/RegisterSlice";

import PageTitleGuest from "../../../layouts/inc/PageTitleGuest";

import {
  // convertDateFormat,
  convertMobileFormat,
  breakKeyDownEnter,
} from "../../../services/helper";

import moment from "moment";

// import useTranslations from "../../../i18n/useTranslations";

import img_icoCheckUser from "../../../assets/img/ci/icon-register/ico-checkUser.svg";

const AuthUser = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateRegister = useSelector((state) => state.register); // ไว้ดึง state
  const stateProfile = useSelector((state) => state.profile);

  // const { t } = useTranslations(); // แปลภาษา

  /* Set state Register {currentPage} on goNext & goBack */
  const navigate = useNavigate();
  const [page, setPage] = useState("auth-confirm");

  const PageRoute = {
    "auth-preview": "/auth-preview",
    "auth-otp": "/auth-otp",
  };

  const setCurrentPage = (currentPage) => {
    const tmpObj = { currentPage: currentPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const setPrevPage = (prevPage) => {
    const tmpObj = { prevPage: prevPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const goBack = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };

  const goNext = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };
  /* End Set state Register {currentPage} on goNext & goBack */

  /* Form */
  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    getFieldState,
  } = useForm({
    defaultValues: {
      isConsent: false,
    },
  });

  // console.log("isValid: ", isValid);
  const [formIsValid, setFormIsValid] = useState(false);

  const checkOnChangeField = () => {
    const _field = getValues();
    let _invalidState = true,
      _invalidFields = [];

    for (const key in _field) {
      const val = _field[key];
      // console.log(key, typeof val, val);

      let _invalid = getFieldState(key).invalid;
      if (val === "" || val === false || val === null) {
        _invalid = true;
      }
      _invalidFields.push(_invalid);
    }

    _invalidState = _invalidFields.includes(true);

    if (_invalidState === true) {
      setFormIsValid(false);
    } else {
      setFormIsValid(true);
    }

    // console.log("onChange", _invalidState);
  };

  useEffect(() => {
    // console.log(formIsValid);
  }, [formIsValid]);
  /* End Form */

  /* Handle click submit form */
  // console.log('onSubmit >>', data);

  const onSubmit = (data) => {
    const updateData = {
      mobileNo: convertMobileFormat(stateRegister.dataUser.mobileNo),
    };

    setDataRegis({
      ...stateRegister.dataUser,
      ...updateData,
      ...data,
    });

    goNext("auth-otp");
  };

  const setDataRegis = (newData = {}) => {
    const tmpObj = { dataUser: newData };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };
  /* End Handle click submit form */

  /* onClick privacy policy save form data temp  */
  const setTempDataRegis = (data) => {
    const updateData = {
      // firstname: data.firstname,
      // lastname: data.lastname,
      // birthDate: data.birthDate,
      // gender: data.gender,
      // mobileNo: data.mobileNo,
      isConsent: watch("isConsent"),
    };
    // console.log("setTempDataRegis >>", updateData);
    setDataRegis({ ...stateRegister.dataUser, ...updateData });
  };
  /* End onClick privacy policy save form data temp */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !", stateRegister);

    // if (stateProfile.lineUid !== null && stateProfile.custNo !== null) {
    //   navigate("/profile");
    // }

    const { dataUser } = stateRegister;

    if (Object.keys(dataUser).length !== 0) {
      // setValue("firstname", dataUser.firstname);
      // setValue("lastname", dataUser.lastname);
      // setValue("birthDate", dataUser.birthDate);
      // setValue("gender", dataUser.gender);
      // setValue("mobileNo", dataUser.mobileNo);
      setValue("isConsent", dataUser.isConsent ? dataUser.isConsent : "");

      let _chkFormIsValid = true;
      Object.entries(dataUser).forEach(([key, val]) => {
        // console.log(key, typeof val, val);
        if (val === "" || val === false || val === null) {
          _chkFormIsValid = false;
        }
      });

      if (_chkFormIsValid === true) {
        setFormIsValid(true);
      }
    } else {
      navigate("/");
    }
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>ห้างเพชรทองโกลด์เด้น 99 จำกัด</title>
        <meta name="title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          name="description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta name="keyword" content="" />
        <meta property="og:title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          property="og:description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="assets/img/share/share-cover.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="GOLDEN99" />
      </Helmet>

      <div id="pageRegisterResult">
        <main>
          <div className="wrapContent">
            <PageTitleGuest
              step={{
                current: 2,
                max: 3,
              }}
            />

            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => breakKeyDownEnter(e)}
            >
              <section id="pRegisterMain">
                <div className="bRegister verify">
                  <h3>
                    <img src={img_icoCheckUser} alt="" />
                    ตรวจสอบข้อมูลส่วนตัว
                  </h3>
                  <div className="bForm">
                    <div className="content">
                      <div className="bContent">
                        <div className="bRow">
                          <div className="bText">
                            รหัสสมาชิก
                            <span className="text">
                              {stateRegister.dataUser.custCode}
                            </span>
                          </div>
                        </div>
                        <div className="bRow">
                          <div className="bText">
                            ชื่อ - นามสกุล
                            <span className="text">{`${stateRegister.dataUser.firstname} ${stateRegister.dataUser.lastname}`}</span>
                          </div>
                        </div>
                        <div className="bRow">
                          <div className="bText">
                            เบอร์โทร
                            <span className="text">
                              {stateRegister.dataUser.mobileNo
                                ? convertMobileFormat(
                                    stateRegister.dataUser.mobileNo,
                                    "-"
                                  )
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="bContent">
                        <div className="bRow">
                          <div className="bText">
                            ประเภทสมาชิก
                            <span className="text">สมาชิก HUG Club</span>
                          </div>
                        </div>
                        <div className="bRow">
                          <div className="bText">
                            เพศ
                            <span className="text">
                              {stateRegister.dataUser.gender === "m"
                                ? "ชาย"
                                : stateRegister.dataUser.gender === "f"
                                ? "หญิง"
                                : ""}
                            </span>
                          </div>
                        </div>

                        <div className="bRow">
                          <div className="bText">
                            วัน/เดือน/ปีเกิด
                            <span className="text">
                              {/* {stateRegister.dataUser.birthDate
                                ? convertDateFormat(
                                    stateRegister.dataUser.birthDate,
                                    "d/m/y"
                                  )
                                : ""} */}
                              {stateRegister.dataUser.birthDate &&
                                moment(stateRegister.dataUser.birthDate).format(
                                  "DD/MM/"
                                ) +
                                  (parseInt(
                                    moment(
                                      stateRegister.dataUser.birthDate
                                    ).format("YYYY")
                                  ) +
                                    543)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="bRow">
                      <p className="remark">
                        *หากข้อมูลไม่ถูกต้อง กรุณาติดต่อ Health up
                        <br />
                        เบอร์ 08x-xxxx-xxx
                      </p>
                    </div>

                    <div className="bRow">
                      <div
                        className={`control-group pdpa ${
                          errors.isConsent ? "error" : ""
                        }`}
                      >
                        <label className="control control--checkbox">
                          <p>
                            {`ข้าพเจ้าได้อ่าน `}
                            <Link
                              to="/privacy-policy"
                              onClick={() =>
                                setTempDataRegis(stateRegister.dataUser)
                              }
                            >
                              นโยบายความเป็นส่วนตัว
                            </Link>
                            {` และยินยอมรับเงื่อนไขของการ เป็นสมาชิก`}
                          </p>
                          <Controller
                            name="isConsent"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: `จำเป็นต้องยินยอมรับเงื่อนไขของการเป็นสมาชิก`,
                            }}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="checkbox"
                                checked={watch("isConsent")}
                                onChange={(e) => {
                                  field.onChange(e.target.checked);
                                  checkOnChangeField();
                                }}
                              />
                            )}
                          />
                          <div className="control__indicator"></div>
                        </label>
                        {errors.isConsent && (
                          <label htmlFor="" className="tError">
                            {errors.isConsent.message}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section id="pActionBtn">
                <div className="bBtn">
                  <button
                    className="btn outline"
                    onClick={(e) => {
                      e.preventDefault();
                      goBack("auth-preview");
                    }}
                  >
                    ย้อนกลับ
                  </button>
                  <button className="btn" disabled={formIsValid ? false : true}>
                    ยืนยัน
                  </button>
                </div>
              </section>
            </form>
          </div>
        </main>
      </div>
    </>
  );
};

export default AuthUser;
