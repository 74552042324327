import { useEffect, useState } from "react";

import jBox from "jbox";
import $ from "jquery";

import useTranslations from "../../i18n/useTranslations";

function ModalAlert({
  open = false,
  title = "",
  subTitle = "",
  content = "", //"หากพบปัญหา<br/>กรุณาติดต่อ สาขา Golden99 ใกล้เคียง<br/>หรือโทรศัพท์ 000-000-xxxx",
  onClose = () => {},
}) {
  const { t } = useTranslations(); // แปลภาษา

  const [ModalAlert, setModalAlert] = useState(null);

  function openModal() {
    ModalAlert.open();
  }

  function closeModal() {
    ModalAlert.close();
    onClose();
  }

  function listenerCloseModal() {
    const elementModalAlert = document.getElementById("ModalAlert");
    elementModalAlert.addEventListener("click", () => {
      closeModal();
    });
  }

  useEffect(() => {
    // console.log('useEffect!!0', open)
    if (ModalAlert && open) {
      openModal();
      listenerCloseModal();
      // setTimeout(() => listenerCloseModal(), 100)
    }
  }, [ModalAlert, open]);

  useEffect(() => {
    // console.log('useEffect!!1')
    const ModalAlert = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 375,
      content: $("#ModalAlert"),
    });
    setModalAlert(ModalAlert);
  }, []);

  return (
    <>
      <div className="bModal bCard" id="ModalAlert">
        <div className="bInner">
          <div
            className="bTitle"
            dangerouslySetInnerHTML={{
              __html: `<span>${title}</span> ${subTitle}`,
            }}
          ></div>
          {content !== "" ? <div
            className="bDetails"
            dangerouslySetInnerHTML={{ __html: content }}
          /> : ''}
          <div className="bBtn" onClick={() => closeModal()}>
            <a className="btn outline btnCloseModal">{t.btn.back}</a>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalAlert;
