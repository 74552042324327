import { get, post } from "../Config";

export function HealthCardForm({ params }) {
  return get({ url: `/api/sb/v1/hsc/healthCardForm`, params});
}

export function createHealthCard({ data }) {
  return post({ url: `/api/sb/v1/hsc/createHealthCard`,data});
}

export function getSearchSlot({ params }) {
  return get({ url: `/api/sb/v1/hsc/slot`,params});
}

export function allHealthCard({ params }){
  return get({url: `/api/sb/v1/hsc/allHealthCard`,params})
}

export function healthCardById({ cardId,params }){
  return get({url: `/api/sb/v1/hsc/healthCard/${cardId}`,params})
}

