import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLiff } from "react-liff";

import { telepharmacy } from "../../services/Api/Module/Customer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Telepharmacy = () => {
  const stateProfile = useSelector((state) => state.profile);

  let { liff } = useLiff();

  /* Handle goto page */
  const navigate = useNavigate();

  const goBack = () => {
    if (liff.isInClient()) {
      liff.closeWindow();
    } else {
      navigate("/");
    }
  };
  /* End Handle goto page */

  /* First event render */
  const [isLoading, setIsLoading] = useState(true);

  const [branch, setBranch] = useState(null);

  const getTelepharmacy = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await telepharmacy({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getTelepharmacy resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getTelepharmacy >> ", e);
    }

    return _return;
  };

  useEffect(() => {
    // console.log("useEffect !", stateProfile);

    // if (stateProfile.id !== 0) {
      getTelepharmacy({
        custId: stateProfile.id,
      }).then((_rsDataTelepharmacy) => {
        // console.log("_rsDataTelepharmacy >>", _rsDataTelepharmacy);

        if (
          _rsDataTelepharmacy.status === true &&
          _rsDataTelepharmacy.result.length !== 0
        ) {
          setBranch(_rsDataTelepharmacy.result);
        }
      });
    // } else {
    //   navigate("/");
    // }
  }, [stateProfile]);
  /* End First event render */

  useEffect(() => {
    // console.log("useEffect !", branch);

    if (branch !== null) {
      setIsLoading(false);
    }
  }, [branch]);

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageConsult">
        <header>
          <div className="tBranchHeader">
            <h1>ปรึกษาเภสัชกร</h1>
            <p>เรายินดีให้คำปรึกษาเกี่ยวกับสุขภาพและยา</p>
          </div>
        </header>
        <main>
          <div className="wrapContent">
            <section id="pConsult">
              {isLoading ? (
                <p>
                  <FontAwesomeIcon icon={faSpinner} spin /> Loading...
                </p>
              ) : (
                <>
                  {branch.lineUrl && (
                    <a className="consultLine" href={branch.lineUrl}></a>
                  )}
                  {branch.pharmcare && (
                    <a className="consultTele" href={branch.pharmcare}></a>
                  )}
                </>
              )}
            </section>

            <section id="pActionBtn">
              <div className="bBtn">
                <button className="btn outline" onClick={goBack}>ย้อนกลับ</button>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default Telepharmacy;
