import React, { useEffect, useState, useLayoutEffect, useRef } from "react";

const CardBranch = (props) => {
  // useEffect(() => {
  //   // console.log("props", props);
  // }, []);

  const [height, setHeight] = useState(0);
  const eleRef = useRef(null);

  useEffect(() => {
    // console.log(props.index, height, props.curHeight);
    props.setNewHeight(props.index, height);
  }, [height]);

  useLayoutEffect(() => {
    const handleResize = () => {
      setHeight(eleRef.current.offsetHeight);
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="bBranchDetail" ref={eleRef}>
      <div className="bText">
        <p className="tDetailHead">{props.dataBranch.nameFe}</p>
        <p>{props.dataBranch.address}</p>
        <div className="bDistance">
          <div className="tDistance">
            <p className="tUnit">ระยะทาง</p>
            <p className="boldText">
              {`${
                props.dataBranch.latitude !== "0" &&
                props.dataBranch.longitude !== "0" &&
                typeof props.dataBranch.distance !== "undefined"
                  ? props.dataBranch.distance
                  : "-"
              }`}{" "}
              กม.
            </p>
          </div>
          <a
            href={`https://www.google.com/maps?hl=th&output=classic&f=d&daddr=${props.dataBranch.latitude}+N,${props.dataBranch.longitude}+E`}
            className="bBtn outline"
            style={
              props.dataBranch.latitude === "0" &&
              props.dataBranch.longitude === "0"
                ? {
                    pointerEvents: "none",
                    cursor: "default",
                    textDecoration: "none",
                    filter: "grayscale(100%)",
                  }
                : {}
            }
          >
            <p>แสดงเส้นทาง</p>
          </a>
        </div>
      </div>
      <div className="bContract">
        <p>ช่องทางการติดต่อเพิ่มเติม:</p>
        <div className="tContract">
          {props.dataBranch.lineUrl && (
            <a href={props.dataBranch.lineUrl} className="logoLine"></a>
          )}
          {props.dataBranch.tel && (
            <a href={`tel:${props.dataBranch.tel}`} className="logoTel"></a>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardBranch;
