import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPromotion, resetPromotion } from "../../../features/PromotionSlice";

import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";

const PromotionDetail = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const statePromotion = useSelector((state) => state.promotion);

  const setDataPromotion = (image) => {
    const tmpObj = { image: image };
    dispatch(setPromotion(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const resetDataPromotion = () => {
    dispatch(resetPromotion()); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const image = e.currentTarget.dataset.image;
    setDataPromotion(image);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* Handle goback */
  const handleGoBack = () => {
    resetDataPromotion();

    navigate(-1);
  };
  /* End Handle goback */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !!", statePromotion);

    if (
      statePromotion.id === 0 &&
      Object.getOwnPropertyNames(statePromotion.data).length === 0
    ) {
      navigate("/profile");
    }
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pagePromotion-details">
        <main>
          <div className="wrapContent">
            <section id="pPromotionDetails">
              <section id="pSlide">
                <Splide
                  hasTrack={false}
                  options={{
                    arrows: false,
                  }}
                  id="slideHeroBanner"
                >
                  <SplideTrack>
                    {typeof statePromotion.data.catalogImg !== "undefined" &&
                    statePromotion.data.catalogImg.length > 0 ? (
                      statePromotion.data.catalogImg.map((item, index) => (
                        <SplideSlide key={index + 1}>
                          <a
                            className="bannerContent"
                            href="#"
                            onClick={handleGotoPage}
                            data-page="promotion/image"
                            data-image={item.thumbnailImg}
                          >
                            <img src={item.thumbnailImg} />
                          </a>
                        </SplideSlide>
                      ))
                    ) : (
                      <SplideSlide>
                        <img src={statePromotion.data.thumbnailImg} />
                      </SplideSlide>
                    )}
                  </SplideTrack>
                </Splide>
              </section>
              <div className="bPromotionDetails">
                <div className="bHead">
                  <div className="bTitle">
                    <h1>{statePromotion.data.name}</h1>
                    <div className="date">
                      <p>
                        <span>
                          วันนี้ -{" "}
                          {new Date(
                            statePromotion.data.endDate
                          ).toLocaleDateString("th-TH", {
                            year: "2-digit",
                            month: "short",
                            day: "numeric",
                          })}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bDetails">
                  <h2>เงื่อนไข</h2>
                  <div className="bRow">
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          statePromotion.data.proDetail &&
                          statePromotion.data.proDetail.replaceAll(
                            "className",
                            "class"
                          ),
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </section>

            <section id="pActionBtn">
              <div className="bBtn">
                <button className="btn outline" onClick={handleGoBack}>
                  ย้อนกลับ
                </button>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default PromotionDetail;
