import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setTransaction } from "../../../../features/TransactionSlice";

const CardReturnTransaction = (props) => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง

  const setDataTransaction = (newData) => {
    const tmpObj = { id: newData.id, data: newData };
    dispatch(setTransaction(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goto transaction detail page */
  const navigate = useNavigate();

  const handleGotoTransactionDetail = (e) => {
    e.preventDefault();
    // console.log("handleGotoTransactionDetail >>", props.dataTransaction);

    setDataTransaction(props.dataTransaction);

    navigate("/return-transaction-history/detail");
  };
  /* End Handle goto transaction detail page */

  // useEffect(() => {
  //   // console.log("props", props);
  // }, []);

  const [height, setHeight] = useState(0);
  const eleRef = useRef(null);

  useEffect(() => {
    // console.log(props.index, height, props.curHeight);
    props.setNewHeight(props.index, height);
  }, [height]);

  useLayoutEffect(() => {
    const handleResize = () => {
      setHeight(eleRef.current.offsetHeight);
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <article ref={eleRef} data-year={props.year} data-month={props.month}>
      <div className="bHead return">
        <p className="return">เลขที่คืนสินค้า</p>
        <p>{props.returnNo}</p>
      </div>
      <div className="bDetails">
        <div className="bRow">
          <p>วันที่ :</p>
          <p>{props.returnDateTh} น.</p>
        </div>
        <div className="bRow">
          <p>จำนวนสินค้า :</p>
          <p>{props.sumProduct && parseInt(props.sumProduct).toLocaleString()}</p>
        </div>
        <div className="bRow price">
          <p>ราคารวมทั้งหมด :</p>
          <p>{props.totalPrice && parseInt(props.totalPrice).toLocaleString()}</p>
        </div>
      </div>
      <div className="bBtn">
        <a href="#" onClick={handleGotoTransactionDetail}>
          คลิกเพื่อดูรายละเอียด
        </a>
      </div>
    </article>
  );
};

export default CardReturnTransaction;
