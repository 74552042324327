import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import useTranslations from "../../i18n/useTranslations";

import img_icoLogo from "../../assets/img/ci/icon-policy/ico-logo.svg";

const Policy = () => {
  const { t } = useTranslations(); // แปลภาษา

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  // useEffect(() => {
  //   console.log(t.lang);
  // }, []);

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pagePolicy">
        <main>
          <div className="wrapContent">
            <section id="pPolicyMain">
              <div className="bPolicy">
                <img src={img_icoLogo} alt="" />
                <div className="bText">
                  <h1>นโยบายความเป็นส่วนตัว</h1>
                  <p>
                    บริษัท เฮลท์ อัพ จำกัด
                    ให้ความสำคัญกับการคุ้มครองข้อมูลส่วนบุคคลของคุณ
                    โดยนโยบายความเป็นส่วนตัวฉบับนี้ได้อธิบายแนวปฏิบัติเกี่ยวกับการเก็บรวบรวม
                    ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล รวมถึงสิทธิต่าง ๆ
                    ของเจ้าของข้อมูลส่วนบุคคล ตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
                  </p>
                  <br />
                  <h4>การเก็บรวบรวมข้อมูลส่วนบุคคล</h4>
                  <p>
                    เราจะเก็บรวบรวมข้อมูลส่วนบุคคลที่ได้รับโดยตรงจากคุณผ่านช่องทาง
                    ดังต่อไปนี้
                  </p>
                  <ul>
                    <li>การสมัครสมาชิก</li>
                    <li>โทรศัพท์</li>
                    <li>อีเมล</li>
                    <li>Line Login</li>
                  </ul>
                  <br />
                  <h4>ประเภทข้อมูลส่วนบุคคลที่เก็บรวบรวม</h4>
                  <p>
                    <b>ข้อมูลส่วนบุคคล</b> เช่น ชื่อ นามสกุล อายุ วันเดือนปีเกิด
                    สัญชาติ เลขประจำตัวประชาชน หนังสือเดินทาง เป็นต้น
                  </p>
                  <p>
                    <b>ข้อมูลการติดต่อ</b> เช่น ที่อยู่ หมายเลขโทรศัพท์ อีเมล
                    เป็นต้น
                  </p>
                  <p>
                    <b>ข้อมูลบัญชี</b> เช่น บัญชีผู้ใช้งาน ประวัติการใช้งาน
                    เป็นต้น
                  </p>
                  <p>
                    <b>หลักฐานแสดงตัวตน</b> เช่น สำเนาบัตรประจำตัวประชาชน
                    สำเนาหนังสือเดินทาง เป็นต้น
                  </p>
                  <p>
                    <b>ข้อมูลการทำธุรกรรมและการเงิน</b> เช่น ประวัติการสั่งซื้อ
                    รายละเอียดบัตรเครดิต บัญชีธนาคาร เป็นต้น
                  </p>
                  <p>
                    <b>ข้อมูลทางเทคนิค</b> เช่น IP address, Cookie ID,
                    ประวัติการใช้งานเว็บไซต์ (Activity Log) เป็นต้น
                  </p>
                  <p>
                    <b>ข้อมูลอื่น ๆ </b>เช่น รูปภาพ ภาพเคลื่อนไหว
                    และข้อมูลอื่นใดที่ถือว่าเป็นข้อมูลส่วนบุคคลตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
                    เราจะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลอ่อนไหว
                    ดังต่อไปนี้ เมื่อเราได้รับความยินยอมโดยชัดแจ้งจากคุณ
                    เว้นแต่กฎหมายกำหนดให้ทำได้
                  </p>
                  <ul>
                    <li>เชื้อชาติ</li>
                    <li>เผ่าพันธ์ุ</li>
                    <li>ความคิดเห็นทางการเมือง </li>
                    <li>ความเชื่อในลัทธิ</li>
                    <li>ศาสนาหรือปรัชญา</li>
                    <li>ประวัติอาชญากรรม</li>
                    <li>ความพิการ</li>
                    <li>ข้อมูลสหภาพแรงงาน </li>
                  </ul>
                  <p>
                    ข้อมูลอื่นใดที่กระทบต่อข้อมูลส่วนบุคคลของคุณตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลประกาศกำหนด
                  </p>
                  <p>
                    <b>ผู้เยาว์ </b>
                    หากคุณมีอายุต่ำกว่า 20 ปีหรือมีข้อจำกัดความสามารถตามกฎหมาย
                    เราอาจเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ
                    เราอาจจำเป็นต้องให้พ่อแม่หรือผู้ปกครองของคุณให้ความยินยอมหรือที่กฎหมายอนุญาตให้ทำได้
                    หากเราทราบว่ามีการเก็บรวบรวมข้อมูลส่วนบุคคลจากผู้เยาว์โดยไม่ได้รับความยินยอมจากพ่อแม่หรือผู้ปกครอง
                    เราจะดำเนินการลบข้อมูลนั้นออกจากเซิร์ฟเวอร์ของเรา
                  </p>
                  <p>
                    <b>วิธีการเก็บรักษาข้อมูลส่วนบุคคล </b>
                    เราจะเก็บรักษาข้อมูลส่วนบุคคลของคุณในรูปแบบเอกสารและรูปแบบอิเล็กทรอนิกส์
                  </p>
                  <p>เราเก็บรักษาข้อมูลส่วนบุคคลของคุณ ดังต่อไปนี้</p>
                  <ul>
                    <li>เซิร์ฟเวอร์บริษัทของเราในประเทศไทย </li>
                    <li>ผู้ให้บริการเซิร์ฟเวอร์ในประเทศไทย </li>
                    <li>ผู้ให้บริการเซิร์ฟเวอร์ในต่างประเทศ </li>
                  </ul>
                  <p>
                    <b>การประมวลผลข้อมูลส่วนบุคคล </b>
                    เราจะเก็บรวบรวม ใช้
                    หรือเปิดเผยข้อมูลส่วนบุคคลของคุณเพื่อวัตถุประสงค์ดังต่อไปนี้
                  </p>
                  <ul>
                    <li>เพื่อสร้างและจัดการบัญชีผู้ใช้งาน </li>
                    <li>เพื่อจัดส่งสินค้าหรือบริการ </li>
                    <li>เพื่อปรับปรุงสินค้า บริการ หรือประสบการณ์การใช้งาน </li>
                    <li>เพื่อการบริหารจัดการภายในบริษัท</li>
                    <li>เพื่อการตลาดและการส่งเสริมการขาย </li>
                    <li>เพื่อการบริการหลังการขาย </li>
                    <li>เพื่อรวบรวมข้อเสนอแนะ </li>
                    <li>เพื่อชำระค่าสินค้าหรือบริการ </li>
                    <li>
                      เพื่อปฏิบัติตามข้อตกลงและเงื่อนไข (Terms and Conditions)
                    </li>
                    <li>เพื่อปฏิบัติตามกฎหมายและกฎระเบียบของหน่วยงานราชการ </li>
                  </ul>
                  <p>
                    <b>การเปิดเผยข้อมูลส่วนบุคคล </b>
                    เราอาจเปิดเผยข้อมูลส่วนบุคคลของคุณให้แก่ผู้อื่นภายใต้ความยินยอมของคุณหรือที่กฎหมายอนุญาตให้เปิดเผยได้
                    ดังต่อไปนี้
                  </p>
                  <p>
                    <b>การบริหารจัดการภายในองค์กร </b>
                    เราอาจเปิดเผยข้อมูลส่วนบุคคลของคุณภายในบริษัทเท่าที่จำเป็นเพื่อปรับปรุงและพัฒนาสินค้าหรือบริการของเรา
                    เราอาจรวบรวมข้อมูลภายในสำหรับสินค้าหรือบริการต่าง ๆ
                    ภายใต้นโยบายนี้เพื่อประโยชน์ของคุณและผู้อื่นมากขึ้น
                  </p>
                  <p>
                    <b>ผู้ให้บริการ </b>
                    เราอาจเปิดเผยข้อมูลส่วนบุคคลของคุณบางอย่างให้กับผู้ให้บริการของเราเท่าที่จำเป็นเพื่อดำเนินงานในด้านต่าง
                    ๆ เช่น การชำระเงิน การตลาด การพัฒนาสินค้าหรือบริการ เป็นต้น
                    ทั้งนี้ ผู้ให้บริการมีนโยบายความเป็นส่วนตัวของตนเอง
                  </p>
                  <p>
                    <b>พันธมิตรทางธุรกิจ </b>
                    เราอาจเปิดเผยข้อมูลบางอย่างกับพันธมิตรทางธุรกิจเพื่อติดต่อและประสานงานในการให้บริการสินค้าหรือบริการ
                    และให้ข้อมูลเท่าที่จำเป็นเกี่ยวกับความพร้อมใช้งานของสินค้าหรือบริการ
                  </p>
                  <p>
                    <b>ระยะเวลาจัดเก็บข้อมูลส่วนบุคคล </b>
                    เราจะเก็บรักษาข้อมูลส่วนบุคคลของคุณไว้ตามระยะเวลาที่จำเป็นในระหว่างที่คุณเป็นลูกค้าหรือมีความสัมพันธ์อยู่กับเราหรือตลอดระยะเวลาที่จำเป็นเพื่อให้บรรลุวัตถุประสงค์ที่เกี่ยวข้องกับนโยบายฉบับนี้
                    ซึ่งอาจจำเป็นต้องเก็บรักษาไว้ต่อไปภายหลังจากนั้น
                    หากมีกฎหมายกำหนดไว้ เราจะลบ ทำลาย
                    หรือทำให้เป็นข้อมูลที่ไม่สามารถระบุตัวตนของคุณได้
                    เมื่อหมดความจำเป็นหรือสิ้นสุดระยะเวลาดังกล่าว
                  </p>
                  <p>
                    <b>สิทธิของเจ้าของข้อมูลส่วนบุคคล </b>
                    ภายใต้กฎหมายคุ้มครองข้อมูลส่วนบุคคล
                    คุณมีสิทธิในการดำเนินการดังต่อไปนี้
                  </p>
                  <p>
                    <b>สิทธิขอถอนความยินยอม (right to withdraw consent) </b>
                    หากคุณได้ให้ความยินยอม เราจะเก็บรวบรวม ใช้
                    หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ
                    ไม่ว่าจะเป็นความยินยอมที่คุณให้ไว้ก่อนวันที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลใช้บังคับหรือหลังจากนั้น
                    คุณมีสิทธิที่จะถอนความยินยอมเมื่อใดก็ได้ตลอดเวลา
                  </p>
                  <p>
                    <b>สิทธิขอเข้าถึงข้อมูล (right to access) </b>
                    คุณมีสิทธิขอเข้าถึงข้อมูลส่วนบุคคลของคุณที่อยู่ในความรับผิดชอบของเราและขอให้เราทำสำเนาข้อมูลดังกล่าวให้แก่คุณ 
                    รวมถึงขอให้เราเปิดเผยว่าเราได้ข้อมูลส่วนบุคคลของคุณมาได้อย่างไร
                  </p>
                  <p>
                    <b>สิทธิขอถ่ายโอนข้อมูล (right to data portability) </b>
                    คุณมีสิทธิขอรับข้อมูลส่วนบุคคลของคุณในกรณีที่เราได้จัดทำข้อมูลส่วนบุคคลนั้นอยู่ในรูปแบบให้สามารถอ่านหรือใช้งานได้ด้วยเครื่องมือหรืออุปกรณ์ที่ทำงานได้โดยอัตโนมัติและสามารถใช้หรือเปิดเผยข้อมูลส่วนบุคคลได้ด้วยวิธีการอัตโนมัติ
                    รวมทั้งมีสิทธิขอให้เราส่งหรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นเมื่อสามารถทำได้ด้วยวิธีการอัตโนมัติ
                    และมีสิทธิขอรับข้อมูลส่วนบุคคลที่เราส่งหรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นโดยตรง
                    เว้นแต่ไม่สามารถดำเนินการได้เพราะเหตุทางเทคนิค
                  </p>
                  <p>
                    <b>สิทธิขอคัดค้าน (right to object) </b>
                    คุณมีสิทธิขอคัดค้านการเก็บรวบรวม ใช้
                    หรือเปิดเผยข้อมูลส่วนบุคคลของคุณในเวลาใดก็ได้
                    หากการเก็บรวบรวม ใช้
                    หรือเปิดเผยข้อมูลส่วนบุคคลของคุณที่ทำขึ้นเพื่อการดำเนินงานที่จำเป็นภายใต้ประโยชน์โดยชอบด้วยกฎหมายของเราหรือของบุคคลหรือนิติบุคคลอื่น
                    โดยไม่เกินขอบเขตที่คุณสามารถคาดหมายได้อย่างสมเหตุสมผลหรือเพื่อดำเนินการตามภารกิจเพื่อสาธารณประโยชน์
                  </p>
                  <p>
                    <b>
                      สิทธิขอให้ลบหรือทำลายข้อมูล (right to erasure/destruction){" "}
                    </b>
                    คุณมีสิทธิขอลบหรือทำลายข้อมูลส่วนบุคคลของคุณหรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวคุณได้
                    หากคุณเชื่อว่าข้อมูลส่วนบุคคลของคุณถูกเก็บรวบรวม ใช้
                    หรือเปิดเผยโดยไม่ชอบด้วยกฎหมายที่เกี่ยวข้องหรือเห็นว่าเราหมดความจำเป็นในการเก็บรักษาไว้ตามวัตถุประสงค์ที่เกี่ยวข้องในนโยบายฉบับนี้
                    หรือเมื่อคุณได้ใช้สิทธิขอถอนความยินยอมหรือใช้สิทธิขอคัดค้านตามที่แจ้งไว้ข้างต้นแล้ว
                  </p>
                  <p>
                    <b>
                      สิทธิขอให้ระงับการใช้ข้อมูล (right to restriction of
                      processing)
                    </b>
                    คุณมีสิทธิขอให้ระงับการใช้ข้อมูลส่วนบุคคลชั่วคราวในกรณีที่เราอยู่ระหว่างตรวจสอบตามคำร้องขอใช้สิทธิขอแก้ไขข้อมูลส่วนบุคคลหรือขอคัดค้านของคุณหรือกรณีอื่นใดที่เราหมดความจำเป็นและต้องลบหรือทำลายข้อมูลส่วนบุคคลของคุณตามกฎหมายที่เกี่ยวข้องแต่คุณขอให้เราระงับการใช้แทน
                  </p>
                  <p>
                    <b>สิทธิขอให้แก้ไขข้อมูล (right to rectification) </b>
                    คุณมีสิทธิขอแก้ไขข้อมูลส่วนบุคคลของคุณให้ถูกต้อง
                    เป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด
                  </p>
                  <p>
                    <b>สิทธิร้องเรียน (right to lodge a complaint) </b>
                    คุณมีสิทธิร้องเรียนต่อผู้มีอำนาจตามกฎหมายที่เกี่ยวข้อง
                    หากคุณเชื่อว่าการเก็บรวบรวม ใช้
                    หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ
                    เป็นการกระทำในลักษณะที่ฝ่าฝืนหรือไม่ปฏิบัติตามกฎหมายที่เกี่ยวข้อง
                    คุณสามารถใช้สิทธิของคุณในฐานะเจ้าของข้อมูลส่วนบุคคลข้างต้นได้
                    โดยติดต่อมาที่เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของเราตามรายละเอียดท้ายนโยบายนี้
                    เราจะแจ้งผลการดำเนินการภายในระยะเวลา 30 วัน
                    นับแต่วันที่เราได้รับคำขอใช้สิทธิจากคุณ
                    ตามแบบฟอร์มหรือวิธีการที่เรากำหนด ทั้งนี้
                    หากเราปฏิเสธคำขอเราจะแจ้งเหตุผลของการปฏิเสธให้คุณทราบผ่านช่องทางต่าง
                    ๆ เช่น ข้อความ (SMS) อีเมล โทรศัพท์ จดหมาย เป็นต้น
                  </p>
                  <p>
                    <b>การโฆษณาและการตลาด </b>
                    เพื่อประโยชน์ในการได้รับสินค้าหรือบริการของเรา
                    เราใช้ข้อมูลของคุณเพื่อวิเคราะห์และปรับปรุงสินค้าหรือบริการ
                    และทำการตลาดผ่าน Google, Facebook, pixel tracking code
                    และอื่น ๆ
                    เราใช้ข้อมูลดังกล่าวเพื่อให้สินค้าหรือบริการเหมาะสมกับคุณ
                    เว็บไซต์ของเราอาจแสดงโฆษณาจากบุคคลที่สามเพื่ออำนวยความสะดวกในการบริการของเรา
                    เช่น Google AdSense, BuySellAds
                    บุคคลที่สามเหล่านี้อาจเข้าถึงข้อมูลส่วนบุคคลของคุณเพื่อดำเนินการเหล่านี้เท่านั้น
                    และมีหน้าที่ไม่เปิดเผยหรือใช้เพื่อวัตถุประสงค์อย่างอื่น
                    เราอาจส่งข้อมูลหรือจดหมายข่าวไปยังอีเมลของคุณ
                    โดยมีวัตถุประสงค์เพื่อเสนอสิ่งที่น่าสนกับคุณ
                    หากคุณไม่ต้องการรับการติดต่อสื่อสารจากเราผ่านทางอีเมลอีกต่อไป
                    คุณสามารถกด "ยกเลิกการติดต่อ"
                    ในลิงก์อีเมลหรือติดต่อมายังอีเมลของเราได้
                  </p>
                  <p>
                    <b>เทคโนโลยีติดตามตัวบุคคล (Cookies) </b>
                    เพื่อเพิ่มประสบการณ์การใช้งานของคุณให้สมบูรณ์และมีประสิทธิภาพมากขึ้น
                    เราใช้คุกกี้ (Cookies)หรือเทคโนโลยีที่คล้ายคลึงกัน
                    เพื่อพัฒนาการเข้าถึงสินค้าหรือบริการ โฆษณาที่เหมาะสม
                    และติดตามการใช้งานของคุณ
                    เราใช้คุกกี้เพื่อระบุและติดตามผู้ใช้งานเว็บไซต์และการเข้าถึงเว็บไซต์ของเรา
                    หากคุณไม่ต้องการให้มีคุกกี้ไว้ในคอมพิวเตอร์ของคุณ
                    คุณสามารถตั้งค่าบราวเซอร์เพื่อปฏิเสธคุกกี้ก่อนที่จะใช้เว็บไซต์ของเราได้
                  </p>
                  <p>
                    <b>การรักษาความมั่งคงปลอดภัยของข้อมูลส่วนบุคคล </b>
                    เราจะรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลของคุณไว้ตามหลักการ
                    การรักษาความลับ (confidentiality) ความถูกต้องครบถ้วน
                    (integrity) และสภาพพร้อมใช้งาน (availability) ทั้งนี้
                    เพื่อป้องกันการสูญหาย เข้าถึง ใช้ เปลี่ยนแปลง แก้ไข
                    หรือเปิดเผย
                    นอกจากนี้เราจะจัดให้มีมาตรการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล
                    ซึ่งครอบคลุมถึงมาตรการป้องกันด้านการบริหารจัดการ
                    (administrative safeguard) มาตรการป้องกันด้านเทคนิค
                    (technical safeguard) และมาตรการป้องกันทางกายภาพ (physical
                    safeguard)
                    ในเรื่องการเข้าถึงหรือควบคุมการใช้งานข้อมูลส่วนบุคคล (access
                    control)
                  </p>
                  <p>
                    <b>การแจ้งเหตุละเมิดข้อมูลส่วนบุคคล </b>
                    ในกรณีที่มีเหตุละเมิดข้อมูลส่วนบุคคลของคุณเกิดขึ้น
                    เราจะแจ้งให้สำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลทราบโดยไม่ชักช้าภายใน
                    72 ชั่วโมง นับแต่ทราบเหตุเท่าที่สามารถกระทำได้
                    ในกรณีที่การละเมิดมีความเสี่ยงสูงที่จะมีผลกระทบต่อสิทธิและเสรีภาพของคุณ
                    เราจะแจ้งการละเมิดให้คุณทราบพร้อมกับแนวทางการเยียวยาโดยไม่ชักช้าผ่านช่องทางต่าง
                    ๆ เช่น เว็บไซต์ ข้อความ (SMS) อีเมล โทรศัพท์ จดหมาย เป็นต้น
                  </p>
                  <p>
                    <b>การแก้ไขเปลี่ยนแปลงนโยบายความเป็นส่วนตัว </b>
                    เราอาจแก้ไขเปลี่ยนแปลงนโยบายนี้เป็นครั้งคราว
                    โดยคุณสามารถทราบข้อกำหนดและเงื่อนไขนโยบายที่มีการแก้ไขเปลี่ยนแปลงนี้ได้ผ่านทางเว็บไซต์ของเรา
                    นโยบายนี้แก้ไขล่าสุดและมีผลใช้บังคับตั้งแต่วันที่ 21 สิงหาคม
                    2567
                  </p>
                  <p>
                    <b>นโยบายความเป็นส่วนตัวของเว็บไซต์อื่น </b>
                    นโยบายความเป็นส่วนตัวฉบับนี้ใช้สำหรับการเสนอสินค้า บริการ
                    และการใช้งานบนเว็บไซต์สำหรับลูกค้าของเราเท่านั้น
                    หากคุณเข้าชมเว็บไซต์อื่นแม้จะผ่านช่องทางเว็บไซต์ของเรา
                    การคุ้มครองข้อมูลส่วนบุคคลต่าง ๆ
                    จะเป็นไปตามนโยบายความเป็นส่วนตัวของเว็บไซต์นั้น
                    ซึ่งเราไม่มีส่วนเกี่ยวข้องด้วย
                  </p>
                  <p>
                    <b>รายละเอียดการติดต่อ </b>
                    หากคุณต้องการสอบถามข้อมูลเกี่ยวกับนโยบายความเป็นส่วนตัวฉบับนี้
                    รวมถึงการขอใช้สิทธิต่าง ๆ
                    คุณสามารถติดต่อเราหรือเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของเราได้
                    ดังนี้
                  </p>
                  <p>
                    <b>ผู้ควบคุมข้อมูลส่วนบุคคล </b>
                    บริษัท เฮลท์ อัพ จำกัด 15/2 ซอยอุดมสุข 9 บางนาเหนือ บางนา
                    กรุงเทพมหานคร 10260 อีเมล info@healthup.co.th เว็บไซต์
                    www.healthup.co.th หมายเลขโทรศัพท์ 027303838
                  </p>
                  <p>
                    <b>เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล </b>
                    วัชรพงษ์ วงศ์ประยูร 15/2 ซอยอุดมสุข 9 บางนาเหนือ บางนา
                    กรุงเทพมหานคร 10260 อีเมล it.watcharapong@healthup.co.th
                    หมายเลขโทรศัพท์ 027303838
                  </p>
                </div>
              </div>
            </section>

            <section id="pActionBtn">
              <div className="bBtn">
                <button className="btn outline" onClick={() => goBack()}>
                  ย้อนกลับ
                </button>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};
export default Policy;
