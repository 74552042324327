import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLiff } from "react-liff";

import VConsole from "vconsole";

import MainLayout from "./layouts/MainLayout";
import PageLoader from "./components/loader/PageLoader";

import AppRouter from "./appRouter"; // Router

import { setProfile } from "./features/ProfileSlice";

import { searchCustomer } from "./services/Api/Module/Customer";

const devMode = false;

function App() {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);

  const [isLoadedProfile, setIsLoadedProfile] = useState(false);
  const [isHasProfile, setIsHasProfile] = useState(false);

  let { isLoggedIn, liff } = useLiff();

  if (!isLoggedIn && devMode) {
    isLoggedIn = devMode; // mock true เอาไว้ dev
  }

  const initialized = useRef(false);

  useEffect(() => {
    // console.log("isLoggedIn >>", isLoggedIn);

    if (isLoggedIn && !initialized.current) {
      initialized.current = true;

      (async () => {
        try {
          const profile = await liff.getProfile();

          if (profile) {
            const sub = liff.getDecodedIDToken().sub;
            // console.log(profile, sub);

            let tmpObj = {
              id: 0,
              custCode: null,
              branch: null,
              lineUid: sub,
            };
            // console.log(tmpObj);

            getSearchCustomer({
              q: "openApp",
              lineUid: sub,
            }).then((_rsData) => {
              // console.log(
              //   "_rsData >>",
              //   _rsData,
              //   _rsData.result.id !== undefined
              // );

              if (typeof _rsData.result.token !== "undefined") {
                window.localStorage.setItem("token", _rsData.result.token);
              }

              if (_rsData.status === true) {
                // console.log(_rsData.result, sub);

                if (typeof _rsData.result.id !== "undefined") {
                  tmpObj = {
                    ...tmpObj,
                    id: _rsData.result.id,
                    custCode: _rsData.result.custCode,
                    branch: _rsData.result.branch,
                  };

                  setIsHasProfile(true);
                }

                dispatch(setProfile(tmpObj));

                setIsLoadedProfile(true);
              }
            });
          } else {
            console.warn(profile);
          }
        } catch (error) {
          console.warn(error);
        }
      })();
    } else {
      return;
    }
  }, [isLoggedIn]);

  useEffect(() => {
    // console.log("isLoadedProfile >>", isLoadedProfile, isHasProfile);

    if (isLoadedProfile) {
      const vConsole = new VConsole();
    }
  }, [isLoadedProfile, isHasProfile]);

  const getSearchCustomer = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await searchCustomer({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getSearchCustomer resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getSearchCustomer >> ", e);
    }

    return _return;
  };

  return (
    <>
      {isLoadedProfile ? (
        <MainLayout>
          <AppRouter isHasProfile={isHasProfile} profileData={stateProfile} />
        </MainLayout>
      ) : (
        <>
          <PageLoader />
        </>
      )}
    </>
  );
}

export default App;
