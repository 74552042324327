// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-dialog-mask{
    background-color: rgba(0, 0, 0, .82);
}
.bForm .control-group.validate [class*=tTitle]:after {
    content: "*";
    color: #F85B56 !important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;AACA;IACI,YAAY;IACZ,yBAAyB;AAC7B","sourcesContent":[".p-dialog-mask{\n    background-color: rgba(0, 0, 0, .82);\n}\n.bForm .control-group.validate [class*=tTitle]:after {\n    content: \"*\";\n    color: #F85B56 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
