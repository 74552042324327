import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTransition, animated } from "react-spring";

import { custPromotion } from "../../../services/Api/Module/Customer";

import PageTitleMember from "../../../layouts/inc/PageTitleMember";
import CardPromotion from "../../../components/card/promotion/CardPromotion";
import MenuMember from "../../../components/menu/MenuMember";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const MyPromotion = ({ articleItem }) => {
  let height = 0;

  const transitions = useTransition(
    articleItem.map((data) => ({
      ...data,
      y:
        (height += data.props.curHeight ? data.props.curHeight + 12 : 220) -
        (data.props.curHeight ? data.props.curHeight + 12 : 220),
    })),
    {
      key: (item) => item.key,
      from: { height: 0, opacity: 0, transform: "translateY(30px)" },
      enter: ({ y, height }) => ({
        y,
        height,
        opacity: 1,
        transform: "translateY(0)",
      }),
      leave: { height: 0, opacity: 0, transform: "translateY(30px)" },
      update: ({ data, y, height }) => ({ data, y, height }),
    }
  );

  return (
    <div
      className="item-group"
      style={{ position: "relative", height: height }}
    >
      {transitions((style, item, t, index) => (
        <animated.div
          className="item-promotion"
          style={{
            ...style,
            zIndex: articleItem.length - index,
            willChange: "transform, height, opacity",
          }}
        >
          {item}
        </animated.div>
      ))}
    </div>
  );
};

const Promotion = () => {
  const stateProfile = useSelector((state) => state.profile);

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* First event render */
  const [isLoading, setIsLoading] = useState(true);

  const [initItem, setInitItem] = useState([]);
  const [initHeight, setInitHeight] = useState([]);
  const [curHeight, setCurHeight] = useState([]);

  const [promotionItem, setPromotionItem] = useState([]);
  const [articleItem, setArticleItem] = useState([]);

  const getCustPromotion = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await custPromotion({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getCustPromotion resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getCustPromotion >> ", e);
    }

    return _return;
  };

  useEffect(() => {
    // console.log("useEffect !", stateProfile);

    if (stateProfile.id === 0) {
      navigate("/");
    } else {
      if (
        Object.getOwnPropertyNames(stateProfile.dataUser).length === 0 ||
        stateProfile.isLoadProfile === false
      ) {
        navigate("/profile");
      } else {
        getCustPromotion({
          custId: stateProfile.id,
        }).then((_rsDataPromotion) => {
          // console.log("_rsDataPromotion >>", _rsDataPromotion);

          if (
            _rsDataPromotion.status === true &&
            _rsDataPromotion.result.length !== 0
          ) {
            const _length = _rsDataPromotion.result.length;
            const _promotion = _rsDataPromotion.result;
            // console.log(_promotion);

            for (let i = 0; i < _length; i++) {
              setInitItem((prevInitItem) => [...prevInitItem, _promotion[i]]);

              setInitHeight((prevCurHeight) => [...prevCurHeight, 0]);
            }
          }

          setIsLoading(false);
        });
      }
    }
  }, []);
  /* End First event render */

  /* Set new height on resize */
  let _tmpHeight = [];

  const setNewHeight = (index, height) => {
    // console.log(index, height);
    if (height !== 0) {
      _tmpHeight[index] = height;
      setCurHeight(_tmpHeight);
    }
  };
  /* End Set new height on resize */

  useEffect(() => {
    // console.log(initItem.length, initItem);

    if (initItem.length > 0) {
      for (let i = 0; i < initItem.length; i++) {
        setPromotionItem((prevPromotionItem) => [
          ...prevPromotionItem,
          <CardPromotion
            key={i + 1}
            index={i}
            setNewHeight={setNewHeight}
            curHeight={curHeight[i]}
            dataPromotion={initItem[i]}
          />,
        ]);

        _tmpHeight[i] = initHeight[i];
      }
    }
  }, [initItem]);

  useEffect(() => {
    if (promotionItem) {
      // console.log(promotionItem.length, promotionItem);

      setArticleItem(promotionItem);
    }
  }, [promotionItem]);

  useEffect(() => {
    // console.log(curHeight.length, curHeight);

    if (curHeight.length > 0) {
      curHeight.map((val, idx) => {
        setPromotionItem((prevPromotionItem) =>
          prevPromotionItem.with(
            idx,
            <CardPromotion
              key={promotionItem[idx].key}
              index={idx}
              setNewHeight={setNewHeight}
              curHeight={val}
              dataPromotion={promotionItem[idx].props.dataPromotion}
            />
          )
        );
      });
    }
  }, [curHeight]);

  useEffect(() => {
    // console.log(articleItem.length, articleItem);
  }, [isLoading, articleItem]);

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageCoupon-promotion">
        <main>
          <div className="wrapContent">
            <PageTitleMember title="โปรโมชั่น" />

            <section id="pSubmenuMain">
              <div className="selectMenu">
                <div
                  className="selectHeader"
                  onClick={handleGotoPage}
                  data-page="coupon"
                >
                  <div className="coupon">
                    <p>แลกคูปอง</p>
                    <div className="underline"></div>
                  </div>
                </div>
                <div
                  className="selectHeader active"
                  onClick={handleGotoPage}
                  data-page="promotion"
                >
                  <div className="promotion">
                    <p>โปรโมชั่น</p>
                    <div className="underline"></div>
                  </div>
                </div>
              </div>

              <div className="bgList">
                <section id="pListCouponMain">
                  <div className="bListCoupon">
                    {isLoading ? (
                      <div className="bNoData">
                        <p>
                          <FontAwesomeIcon icon={faSpinner} spin /> Loading...
                        </p>
                      </div>
                    ) : (
                      <>
                        {articleItem.length === 0 ? (
                          <div className="bNoData">
                            <p>{`ยังไม่มีโปรโมชั่นในขณะนี้`}</p>
                          </div>
                        ) : (
                          <div className="bGroup ">
                            <MyPromotion articleItem={articleItem} />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </section>
              </div>
            </section>

            <MenuMember pageActive="promotion" />
          </div>
        </main>
      </div>
    </>
  );
};

export default Promotion;
