import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useTranslations from "../../i18n/useTranslations";

import img_picHeadBanner from "../../assets/img/ci/pic-head-banner.png";
import img_icoBanner from "../../assets/img/ci/icon-banner/ico-banner.png";

function PageTitleGuest(props) {
  const stateProfile = useSelector((state) => state.profile); // ไว้ดึง state

  const { t } = useTranslations();

  /* First event render */
  // useEffect(() => {
  //   console.log(stateProfile.lineUid);
  // }, []);
  /* End First event render */

  return (
    <>
      <section id="pTiltlePage">
        <div className="bTitle">{props.title && <h1>{props.title}</h1>}</div>
      </section>
    </>
  );
}
export default PageTitleGuest;
