import { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";

const ModalLoading = ({
  open = false,
  onClose = () => {},
  onConfirm = () => {},
}) => {
  return (
    <Dialog
      visible={open}
      style={{ width: "325px" }}
      onHide={() => onClose()}
      draggable={false}
      resizable={false}
      closable={false}
    >
      <div class="bModal bCard" id="modalPrepareUpdate">
        <img src="./assets/img/ci/ico-healthservice/ico-logo-loading.svg" />
        <h2>กำลังจัดเตรียมข้อมูลของคุณ...</h2>
        <div class="loading-container">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalLoading;
