import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PromotionImage = () => {
  const statePromotion = useSelector((state) => state.promotion);

  /* Handle goback */
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  /* End Handle goback */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !!", statePromotion);

    if (
      statePromotion.id === 0 &&
      Object.getOwnPropertyNames(statePromotion.data).length === 0
    ) {
      navigate("/profile");
    }
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pagePromotion-picture">
        <main>
          <div className="wrapContent">
            <section id="pPromotionPicture">
              <a href={statePromotion.image} target="_blank">
                <img src={statePromotion.image} />
              </a>
            </section>

            <section id="pActionBtn">
              <div className="bBtn">
                <a className="btn outline" href="#" onClick={handleGoBack}>
                  ย้อนกลับ
                </a>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default PromotionImage;
