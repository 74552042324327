import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTransition, animated } from "react-spring";

import { transaction } from "../../../../services/Api/Module/Customer";

import PageTitleMember from "../../../../layouts/inc/PageTitleMember";
import CardBuyTransaction from "../../../../components/card/history/transaction/CardBuyTransaction";
import CardReturnTransaction from "../../../../components/card/history/transaction/CardReturnTransaction";
import ModalTransactionFilter from "../../../../components/modal/history/transaction/ModalTransactionFilter";
import MenuMember from "../../../../components/menu/MenuMember";

import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const MyTransaction = ({ articleItem }) => {
  let height = 0;

  const transitions = useTransition(
    articleItem.map((data) => ({
      ...data,
      y:
        (height += data.props.curHeight ? data.props.curHeight + 16 : 220) -
        (data.props.curHeight ? data.props.curHeight + 16 : 220),
    })),
    {
      key: (item) => item.key,
      from: { height: 0, opacity: 0, transform: "translateY(30px)" },
      enter: ({ y, height }) => ({
        y,
        height,
        opacity: 1,
        transform: "translateY(0)",
      }),
      leave: { height: 0, opacity: 0, transform: "translateY(30px)" },
      update: ({ data, y, height }) => ({ data, y, height }),
    }
  );

  return (
    <div
      className="item-group"
      style={{ position: "relative", height: height }}
    >
      {transitions((style, item, t, index) => (
        <animated.div
          className="item-transaction"
          style={{
            ...style,
            zIndex: articleItem.length - index,
            willChange: "transform, height, opacity",
          }}
        >
          {item}
        </animated.div>
      ))}
    </div>
  );
};

const TransactionHistory = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* Modal filter */
  const [configYear, setConfigYear] = useState({
    maxYear: moment().year(),
    minYear: moment().year(),
  });

  const [isModalShow, setIsModalShow] = useState(false);
  const [isFilterSubmit, setIsFilterSubmit] = useState(false);

  const handleOpenModalFilter = (e) => {
    e.preventDefault();

    setIsModalShow(true);
  };

  const handleCloseModalFilter = () => {
    setIsModalShow(false);
  };

  const handleSubmitFilter = (dataFilter, submitFilter) => {
    // console.log("dataFilter!! >>>", dataFilter, submitFilter);
    const _year = dataFilter.year !== "" ? dataFilter.year : "";
    const _month = dataFilter.month !== "" ? parseInt(dataFilter.month) : "";

    // let _filterItem = transactionItem;

    // if (_year !== "" || _month !== "") {
    //   if (_year !== "") {
    //     _filterItem = _filterItem.filter((item) => {
    //       if (item.props.year.toString().includes(_year)) {
    //         return item;
    //       }
    //     });
    //   }

    //   if (_month !== "") {
    //     _filterItem = _filterItem.filter((item) => {
    //       if (item.props.month.includes(_month)) {
    //         return item;
    //       }
    //     });
    //   }

    //   setArticleItem(_filterItem);
    // } else {
    //   setArticleItem(transactionItem);
    // }

    if (submitFilter) {
      let payload = {
        customer: stateProfile.id,
      };

      if (_year !== "" || _month !== "") {
        payload.date = `${_year !== "" ? _year : "0000"}-${
          _month !== "" ? _month.toString().padStart(2, "0") : "00"
        }`;
      }

      getTransaction(payload).then((_rsDataTransaction) => {
        // console.log("_rsDataTransaction >>", _rsDataTransaction);

        setInitItem([]);
        setInitHeight([]);
        setCurHeight([]);

        setTransactionItem([]);
        setArticleItem([]);

        if (
          _rsDataTransaction.status === true &&
          _rsDataTransaction.result.length !== 0
        ) {
          const _length = _rsDataTransaction.result.transaction.length;
          const _transaction = _rsDataTransaction.result.transaction;
          // console.log(_transaction);

          for (let i = 0; i < _length; i++) {
            setInitItem((prevInitItem) => [...prevInitItem, _transaction[i]]);

            setInitHeight((prevCurHeight) => [...prevCurHeight, 0]);
          }
        }
      });

      setIsFilterSubmit(true);
    }
  };

  useEffect(() => {
    // console.log(isFilterSubmit);
  }, [isFilterSubmit]);
  /* End Modal filter */

  /* First event render */
  const [isLoading, setIsLoading] = useState(true);

  const [initItem, setInitItem] = useState([]);
  const [initHeight, setInitHeight] = useState([]);
  const [curHeight, setCurHeight] = useState([]);

  const [transactionItem, setTransactionItem] = useState([]);
  const [articleItem, setArticleItem] = useState([]);

  const getTransaction = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await transaction({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getTransaction resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getTransaction >> ", e);
    }

    return _return;
  };

  useEffect(() => {
    // console.log("useEffect !", stateProfile);

    if (stateProfile.id === 0) {
      navigate("/");
    } else {
      if (
        Object.getOwnPropertyNames(stateProfile.dataUser).length === 0 ||
        stateProfile.isLoadProfile === false
      ) {
        navigate("/profile");
      } else {
        getTransaction({
          customer: stateProfile.id,
        }).then((_rsDataTransaction) => {
          // console.log("_rsDataTransaction >>", _rsDataTransaction);
          if (
            _rsDataTransaction.status === true &&
            _rsDataTransaction.result.length !== 0
          ) {
            if (
              typeof _rsDataTransaction.result.transaction !== "undefined" &&
              _rsDataTransaction.result.transaction.length > 0
            ) {
              const _length = _rsDataTransaction.result.transaction.length;
              const _transaction = _rsDataTransaction.result.transaction;
              // console.log(_transaction);

              for (let i = 0; i < _length; i++) {
                setInitItem((prevInitItem) => [
                  ...prevInitItem,
                  _transaction[i],
                ]);
                setInitHeight((prevCurHeight) => [...prevCurHeight, 0]);
              }
            }

            if (typeof _rsDataTransaction.result.year !== "undefined") {
              setConfigYear({
                maxYear: _rsDataTransaction.result.year.maxYear,
                minYear: _rsDataTransaction.result.year.minYear,
              });
            }
          }

          setIsLoading(false);
        });
      }
    }
  }, []);
  /* End First event render */

  /* Set new height on resize */
  let _tmpHeight = [];

  const setNewHeight = (index, height) => {
    // console.log(index, height);
    if (height !== 0) {
      _tmpHeight[index] = height;
      setCurHeight(_tmpHeight);
    }
  };
  /* End Set new height on resize */

  useEffect(() => {
    // console.log(initItem.length, initItem);

    if (initItem.length > 0) {
      for (let i = 0; i < initItem.length; i++) {
        if (initItem[i].type === "buy") {
          setTransactionItem((prevTransactionItem) => [
            ...prevTransactionItem,
            <CardBuyTransaction
              key={i + 1}
              index={i}
              setNewHeight={setNewHeight}
              curHeight={curHeight[i]}
              year={moment(initItem[i].buyDate).year()}
              month={moment(initItem[i].buyDate).format("M")}
              buyNo={initItem[i].buyNo}
              buyDate={moment(initItem[i].buyDate).format(
                "DD/MM/YYYY HH:mm:ss"
              )}
              buyDateTh={moment(initItem[i].buyDate).format(
                "DD/MM/" +
                  (parseInt(moment(initItem[i].buyDate).format("YYYY")) + 543) +
                  " " +
                  moment(initItem[i].buyDate).format("HH:mm")
              )}
              sumProduct={initItem[i].sumProduct}
              totalPrice={initItem[i].totalPrice}
              type={initItem[i].type}
              dataTransaction={initItem[i]}
            />,
          ]);
        } else if (initItem[i].type === "return") {
          setTransactionItem((prevTransactionItem) => [
            ...prevTransactionItem,
            <CardReturnTransaction
              key={i + 1}
              index={i}
              setNewHeight={setNewHeight}
              curHeight={curHeight[i]}
              year={moment(initItem[i].returnDate).year()}
              month={moment(initItem[i].returnDate).format("M")}
              returnNo={initItem[i].returnNo}
              returnDate={moment(initItem[i].returnDate).format(
                "DD/MM/YYYY HH:mm:ss"
              )}
              returnDateTh={moment(initItem[i].returnDate).format(
                "DD/MM/" +
                  (parseInt(moment(initItem[i].returnDate).format("YYYY")) +
                    543) +
                  " " +
                  moment(initItem[i].returnDate).format("HH:mm")
              )}
              sumProduct={initItem[i].sumProduct}
              totalPrice={initItem[i].totalPrice}
              type={initItem[i].type}
              dataTransaction={initItem[i]}
            />,
          ]);
        }

        _tmpHeight[i] = initHeight[i];
      }
    }
  }, [initItem]);

  useEffect(() => {
    if (transactionItem) {
      // console.log(transactionItem.length, transactionItem);

      setArticleItem(transactionItem);
    }
  }, [transactionItem]);

  useEffect(() => {
    // console.log(curHeight.length, curHeight);

    if (curHeight.length > 0) {
      curHeight.map((val, idx) => {
        if (transactionItem[idx].props.type === "buy") {
          setTransactionItem((prevTransactionItem) =>
            prevTransactionItem.with(
              idx,
              <CardBuyTransaction
                key={transactionItem[idx].key}
                index={idx}
                setNewHeight={setNewHeight}
                curHeight={val}
                year={moment(
                  transactionItem[idx].props.dataTransaction.buyDate
                ).year()}
                month={moment(
                  transactionItem[idx].props.dataTransaction.buyDate
                ).format("M")}
                buyNo={transactionItem[idx].props.buyNo}
                buyDate={transactionItem[idx].props.buyDate}
                buyDateTh={transactionItem[idx].props.buyDateTh}
                sumProduct={transactionItem[idx].props.sumProduct}
                totalPrice={transactionItem[idx].props.totalPrice}
                type={transactionItem[idx].props.type}
                dataTransaction={transactionItem[idx].props.dataTransaction}
              />
            )
          );
        } else if (transactionItem[idx].props.type) {
          setTransactionItem((prevTransactionItem) =>
            prevTransactionItem.with(
              idx,
              <CardReturnTransaction
                key={transactionItem[idx].key}
                index={idx}
                setNewHeight={setNewHeight}
                curHeight={val}
                year={moment(
                  transactionItem[idx].props.dataTransaction.returnDate
                ).year()}
                month={moment(
                  transactionItem[idx].props.dataTransaction.returnDate
                ).format("M")}
                returnNo={transactionItem[idx].props.returnNo}
                returnDate={transactionItem[idx].props.returnDate}
                returnDateTh={transactionItem[idx].props.returnDateTh}
                sumProduct={transactionItem[idx].props.sumProduct}
                totalPrice={transactionItem[idx].props.totalPrice}
                type={transactionItem[idx].props.type}
                dataTransaction={transactionItem[idx].props.dataTransaction}
              />
            )
          );
        }
      });
    }
  }, [curHeight]);

  useEffect(() => {
    // console.log(articleItem.length, articleItem);
  }, [isLoading, articleItem, isModalShow]);

  return (
    <>
      <Helmet>
        <title>Health Up</title>
        <meta name="title" content="Health Up" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Health Up" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Health Up" />
      </Helmet>

      <div id="pageBuyHistory">
        <main>
          <div className="wrapContent">
            <PageTitleMember title="ประวัติการซื้อสินค้า" />

            <ModalTransactionFilter
              open={isModalShow}
              onClose={handleCloseModalFilter}
              maxYear={configYear.maxYear}
              minYear={configYear.minYear}
              submitCallback={handleSubmitFilter}
            />

            <div className="bgList">
              <section id="pFilterMain">
                <div className="bFilter">
                  <p className="btnFilter" onClick={handleOpenModalFilter}>
                    ทั้งหมด
                  </p>
                </div>
              </section>
            </div>
            <section id="pBuyHistoryMain">
              <div className="bBuyHistory">
                {isLoading ? (
                  <div className="bNoData">
                    <p>
                      <FontAwesomeIcon icon={faSpinner} spin /> Loading...
                    </p>
                  </div>
                ) : (
                  <>
                    {articleItem.length === 0 ? (
                      <>
                        {isFilterSubmit ? (
                          <div className="bNoData">
                            <p>ไม่พบการค้นหาประวัติการซื้อสินค้า</p>
                          </div>
                        ) : (
                          <div className="bNoData">
                            <p>ยังไม่มีประวัติการซื้อสินค้า</p>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="bGroup">
                        <MyTransaction articleItem={articleItem} />
                      </div>
                    )}
                  </>
                )}
              </div>
            </section>

            <MenuMember pageActive="history" />
          </div>
        </main>
      </div>
    </>
  );
};

export default TransactionHistory;
