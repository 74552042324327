import { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import _, { set } from "lodash";
import helper from "../../../../services/helper";
const ModalPill = ({
  open = false,
  onClose = () => {},
  onConfirm = () => {},
  pill = [],
  selected = [],
  other = ""
}) => {
  const [isOtherCheck, setIsOtherCheck] = useState(false);
  const [otherPillValue, setOtherPillValue] = useState("");
  const [selectedPillId, setSelectedPillId] = useState([]);
  const handleSubmit = () => {
    if(!isOtherCheck){
      setOtherPillValue("");
    }
    onConfirm(selectedPillId, otherPillValue);
  };
  const handleOtherCheck = () => {
    setIsOtherCheck(prevState => {
      if (prevState) {
        setOtherPillValue("");
      }
      return !prevState;
    });
  };
  useEffect(() => {
    setSelectedPillId(selected);
    if(other!=""){
      setIsOtherCheck(true);
      setOtherPillValue(other);
    }
    console.log('s',selected)
  }, [selected]);

  return (
    <Dialog
      visible={open}
      style={{ width: "325px" }}
      onHide={() => onClose()}
      draggable={false}
      resizable={false}
      closable={false}
    >
      <div
        className="bModal bCard"
        id="modalpill"
        style={{ display: "block" }}
      >
        <div className="btnClose btnCloseModal" onClick={onClose}>
          <img src="./assets/img/ci/icon-modal/ico-xmark-healthservice.svg" />
        </div>
        <div className="bDetail">
          <div className="bHead">
            <p className="tBold">ระบุประวัติแพ้ยาของคุณ</p>
            <p>เลือกได้มากกว่า 1 ข้อ</p>
          </div>
          <div className="bInput">
            {pill?.map((item) => (
              <div className="bSelect" key={item.id}>
                <label className="control control--checkbox">
                  {item.name}
                  <input
                    type="checkbox"
                    value={item.id}
                    checked={selectedPillId.some(selected => selected == item.id)}
                    onChange={() => {
                      let updateState = helper.FNFORM.handleSelectChange(selectedPillId, item.id)
                      setSelectedPillId(updateState)
                    }}
                  />
                  <div className="control__indicator"></div>
                </label>
              </div>
            ))}
            <div className="bSelect">
              <label className="control control--checkbox">
                อื่นๆ
                <input
                  type="checkbox"
                  checked={isOtherCheck}
                  onChange={handleOtherCheck}
                />
                <div className="control__indicator"></div>
              </label>
            </div>
            {isOtherCheck && (
              <div class="control-group">
              <input
                type="text"
                placeholder="ระบุชื่อโรคประจำตัว"
                onChange={(e) => {
                  setOtherPillValue(e.target.value);
                }}
                value={otherPillValue}
              />
              </div>
            )}
          </div>

          <button
            className="btn"
            onClick={handleSubmit}
            disabled={isOtherCheck && otherPillValue.trim() == ""}
          >
            ยืนยัน
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalPill;
